import React, {useEffect, useState} from "react"
import {Card, Row, Col, Form} from "antd";
import {useDispatch} from "react-redux";
import {getFinalChangesInInrFxn, reeudoRatesFxn, reeudoSourceFxn} from "../actions";
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import "./styles.less"
import {displayDate, displayTime} from "../../../components/_utils/appUtils";
import GetEachFormFields from "../../../components/_utils/appFormUtils";
import _ from "lodash"

const rupeeSymbol = "₹"
const options = {
    autoplay: false,
    loop: false,
    nav: true,
    dots: false,
    // dotClass: 'owl-dot dotNone',
    margin: 15,
    autoplayTimeout: 2000,
    infinite: false,
    speed: 1500,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    autoplaySpeed: 2000,
    responsive: {
        0: {
            items: 6,
            nav: true
        },
        600: {
            items: 6,
            nav: false
        },
        1000: {
            items: 6,
            nav: true,
            loop: false
        }
    }
    // autoWidth:true,

}

const RenderRow = (props) => {
    let {value, label} = props;
    return (
        <Col span={6}>
            {label}
            <h5 className={'font-weight-600'}>{value}</h5>
        </Col>
    )
}

const ReedosComponent = (props) => {
    const {form: {getFieldDecorator, setFieldsValue, getFieldValue, resetFields}} = props
    let dispatch = useDispatch()
    let [reeudoData, setReeudoData] = useState({})
    let [currencyList, setCurrencyList] = useState([])
    let [sourceList, setSourceList] = useState([])
    let [selectedCurrencyObj, setSelectedCurrencyObj] = useState({})
    let [finalChangesState, setFinalChangesState] = useState({})

    const events = {
        loadRates: async () => {
            let {data, success} = await dispatch(reeudoRatesFxn())
            if (success) {
                let dataArr = []
                let {currencyData, currencyOrder} = data;
                _.each(currencyOrder, (item) => {
                    let url = `https://static-content-prd-reeudo.s3.ap-south-1.amazonaws.com/country-flags/${item}2x.png`
                    let resp = currencyData && currencyData[item] ? currencyData[item] : null;
                    let obj = {
                        currency: item,
                        ...resp,
                        imgPath: url
                    }
                    dataArr.push(obj);
                })
                setCurrencyList(dataArr)
                setReeudoData(data)
            }
        },
        loadSource: async () => {
            let {data, success} = await dispatch(reeudoSourceFxn())
            if (success) {
                setSourceList(data);
            }
        },
        setDefaultParams: () => {
            let currency = getFieldValue('currency')
            if (!currency) {
                setTimeout(() => {
                    setFieldsValue({
                        amount: 100,
                        currency: currencyList && currencyList.length ? currencyList[0].currency : "",
                        customerRate: currencyList && currencyList.length ? (parseInt(currencyList[0].yourRate) + 1) : "",
                    })
                }, 100)
                if (currencyList && currencyList.length) {
                    let currentCurrency = currencyList[0]
                    setSelectedCurrencyObj(currentCurrency)
                }
            }
        },
        setDefaultSource: () => {
            setTimeout(() => {
                setFieldsValue({
                    sourceId: sourceList && sourceList.length ? sourceList[0].id : "",
                })
            }, 100)
        },
        chooseCurrency: (currency) => {
            let findDoc = _.find(currencyList, (item) => {
                return item.currency == currency
            })
            if (findDoc) {
                setSelectedCurrencyObj(findDoc)
                setTimeout(() => {
                    setFieldsValue({
                        customerRate: findDoc ? (parseInt(findDoc.yourRate) + 1) : "",
                    })
                }, 100)
            }
        },
        getFinalChangesFxn: async () => {
            let obj = {
                currency: getFieldValue('currency'),
                amount: getFieldValue('amount'),
                customerRate: getFieldValue('customerRate'),
                sourceId: getFieldValue('sourceId'),
            }
            if (obj.currency && obj.amount && obj.customerRate && obj.sourceId) {
                let {data, success} = await dispatch(getFinalChangesInInrFxn(obj))
                if (success && data && data.charges) {
                    setFinalChangesState(data.charges)
                }
            }
        }
    }

    useEffect(() => {
        events.loadRates()
        events.loadSource()
    }, [])
    useEffect(() => {
        events.setDefaultParams()
    }, [currencyList])

    useEffect(() => {
        events.setDefaultSource()
    }, [sourceList])

    useEffect(() => {
        events.getFinalChangesFxn()
    }, [getFieldValue('currency'), getFieldValue('amount'), getFieldValue('customerRate'), getFieldValue('sourceId')])

    let inputTypes = {
        fields: [
            {
                key: 'currency',
                label: 'Currency',
                type: 'select',
                required: true,
                options: currencyList,
                keyAccessor: x => x.currencyCode,
                valueAccessor: (x) => {
                    return (
                        <>
                            <img src={x.imgPath} style={{height: 25}}/>
                            <span className={'ml20 font-weight-600'}>{x.currencyCode} - {x.currencyName}</span>
                        </>
                    )
                },
                onChange: x => {
                    events.chooseCurrency(x);
                    setFieldsValue({
                        currency: x,
                    })
                }
            },
            {key: 'customerRate', label: 'Customer Rate', required: true},
            {key: 'amount', label: 'Amount', required: true},
            {
                key: 'sourceId',
                label: 'Source',
                type: 'select',
                required: true,
                options: sourceList,
                keyAccessor: x => x.id,
                valueAccessor: (x) => x.sourceOfFund,
                onChange: x => {
                    events.chooseCurrency(x);
                    setFieldsValue({
                        source: x,
                    })
                }
            },
        ]
    }

    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }


    return (
        <>
            <div className='row mt-4'>
                <div className='col-lg-12'>
                    <div className='card'>
                        <div className='table-head d-flex align-items-center all-student-search'>

                            <div className={'flexRow'}>
                                <div className={'mr50'}>
                                    IBR Rate<br/>
                                    <strong>{reeudoData.date ? displayDate(reeudoData.date, 'DD/MM/YYYY') : null}</strong>
                                </div>
                                <div>
                                    Last Updated<br/>
                                    <strong>{reeudoData.date ? displayTime(reeudoData.date, 'hh:mm:ss a') : null}</strong>
                                </div>
                            </div>
                        </div>
                        <div className='card-body table-responsive'>
                            <div className={'currency-list reeudo-slider'}>
                                <OwlCarousel
                                    className="owl-theme "
                                    {...options}>
                                    {currencyList && currencyList.length ? currencyList.map((item) => {
                                        return (
                                            <Card className={'currency-card'}>
                                                <div>
                                                    Live IBR / <span className={'currency-name'}>{item.currency}</span>
                                                </div>
                                                <div className={'currency-value'}>
                                                    {item.rate}
                                                </div>
                                                <div className={'your-rate'}>
                                                    Your Rate<br/>
                                                    <div className={'rate'}>
                                                        {item.yourRate}
                                                    </div>
                                                </div>
                                            </Card>
                                        )
                                    }) : null}
                                </OwlCarousel>
                            </div>
                        </div>
                        <div className='card-body table-responsive'>
                            <Row gutter={24}>
                                {inputTypes.fields.map((item, key) => {
                                    return (
                                        <Col span={6} key={key}>
                                            <GetEachFormFields
                                                item={item}
                                                getFieldDecorator={getFieldDecorator}
                                                formItemLayout={formItemLayout}/>
                                        </Col>
                                    )
                                })}
                            </Row>
                            <Row className={'mt20'} gutter={15}>
                                <Col span={18}>
                                    <Card>
                                        <Row>
                                            <RenderRow label={'Currency Type'}
                                                       value={finalChangesState && finalChangesState.fromCurrency ? finalChangesState.fromCurrency : ""}/>
                                            <RenderRow label={'Foreign Currency Amount'}
                                                       value={finalChangesState && finalChangesState.fromCurrencyAmount ? finalChangesState.fromCurrencyAmount : ""}/>
                                            <RenderRow label={'Rate'}
                                                       value={finalChangesState && finalChangesState.fxRate ? `${rupeeSymbol} ${finalChangesState.fxRate}` : ""}/>
                                            <RenderRow label={'Gross Amount'}
                                                       value={finalChangesState && finalChangesState.base ? `${rupeeSymbol} ${finalChangesState.base}` : ""}/>
                                        </Row>
                                        <Row className={'mt10'}>
                                            <RenderRow label={'Foreign Conversion GST Tax @18 %'}
                                                       value={finalChangesState && finalChangesState.gst ? `${rupeeSymbol} ${finalChangesState.gst}` : 0}/>
                                            <RenderRow label={'Bank Processing Fee'}
                                                       value={finalChangesState && finalChangesState.bank ? `${rupeeSymbol} ${finalChangesState.bank}` : 0}/>
                                            <RenderRow label={'Nostro Charge'}
                                                       value={finalChangesState && finalChangesState.nostro ? `${rupeeSymbol} ${finalChangesState.nostro}` : 0}/>
                                            <RenderRow label={'TCS Charge*'}
                                                       value={finalChangesState && finalChangesState.tcs ? `${rupeeSymbol} ${finalChangesState.tcs}` : 0}/>
                                        </Row>
                                        <div className={'mt5 gray-color'}>
                                            <small>*The Final TCS may change based on the historical transactions of the
                                                sender and the "Source of Fund"</small>
                                        </div>
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card>
                                        <div>
                                            Grand Total<br/>
                                            <h4 className={'font-weight-600 colorPrimaryDark'}>
                                                {rupeeSymbol} {finalChangesState && finalChangesState.total ? finalChangesState.total : 0}
                                            </h4>
                                        </div>
                                        <a className={'btn btn-primary roundNew lg mt40 w-100'}> Get Quote</a>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const WrapReedosComponent = Form.create()(ReedosComponent)

export default WrapReedosComponent