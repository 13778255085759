import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  Col,
  Select,
  Row, DatePicker, Avatar
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import { getUrlPushWrapper } from '../../routes'
import {
  countryIds,
  dateFilterRange
} from '../../components/_utils/appUtils'
import moment from 'moment'
import {
  getMarketingUserApplicationRecordFxn,
  getPublicUniversityApplicationsData
} from './actions/action'

const { RangePicker } = DatePicker


const ColumnComponent = (props) => {
  let { current, previous, percentage } = props
  return (
    <>
      Prev. : {previous} <br />
      Curr. : {current} <br />
      <div className={'mt5 font-weight-600 '}>
        ({percentage}%
        <span className={`${percentage >= 0 ? 'arrow-up greenColor' : 'arrow-down redColor'}`}>
        {percentage >= 0 ? '▲' : '▼'}
      </span>)
      </div>

    </>
  )
}


const LiveReportingList = (props) => {
  const [selectUser, setSelectUser] = useState('')
  const [toDate, setToDate] = useState(moment().startOf('day'))
  const [fromDate, setFromDate] = useState(moment().subtract(7, 'days').startOf('day'))
  const [marketingUserApplicationRecord, setMarketingUserApplicationRecord] = useState([])
  const [countryName, setCountryName] = useState('')
  const [countryId, setCountryId] = useState('')
  const [countryStatusArr, setCountryStatusArr] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    reloadData()
  }, [])

  let reloadData = async () => {
    let resp = await getParamsForApplicationList()
    if (resp && resp.universityId) {
      setCountryId(countryIds.canada)
      fetchLiveReportingList(resp.universityId)
    }
    if (resp && resp.countryId) {
      setCountryId(resp.countryId)
      fetchPublicUniversityData(resp.countryId)
    }
    if (resp && resp.countryName) {
      setCountryName(resp.countryName)
    }
  }

  const loadUserReport = async () => {
    let searchParams = new URLSearchParams(window.location.search)
    let universityId = searchParams.get('universityId')
    let countryName = searchParams.get('countryName')
    let universityName = searchParams.get('universityName')
    let countryId = searchParams.get('countryId')
    let obj = {}
    if (universityId) {
      obj.universityId = universityId
    }
    if (countryName) {
      obj.countryName = countryName
    }
    if (universityName) {
      obj.universityName = universityName
    }
    if (countryId) {
      obj.countryId = countryId
    }
    dispatch(getUrlPushWrapper('liveReportingList', obj))
    if (universityId) {
      fetchLiveReportingList(universityId)
    } else {
      fetchPublicUniversityData(countryId)
    }
  }


  const getParamsForApplicationList = () => {
    return new Promise((resolve) => {
      let searchParams = new URLSearchParams(window.location.search)
      let universityId = searchParams.get('universityId')
      let countryName = searchParams.get('countryName')
      let universityName = searchParams.get('universityName')
      let countryId = searchParams.get('countryId')
      let obj = {}
      if (universityId) {
        obj.universityId = universityId
      }
      if (countryName) {
        obj.countryName = countryName
      }
      if (universityName) {
        obj.universityName = universityName
      }
      if (countryId) {
        obj.countryId = countryId
      }
      resolve(obj)
    })
  }

  const fetchLiveReportingList = async (universityId) => {
    let params = {}
    if (universityId) {
      params.universityId = universityId
    }
    if (toDate) {
      params.toDate = moment(toDate).format('DD-MM-YYYY')
    }
    if (fromDate) {
      params.fromDate = moment(fromDate).format('DD-MM-YYYY')
    }
    console.log(params, 'params')
    const { data } = await dispatch(getMarketingUserApplicationRecordFxn(params))
    if (data) {
      setMarketingUserApplicationRecord(data.data)
      setCountryStatusArr(data.countryStatusArr)
    }
  }
  const fetchPublicUniversityData = async (countryId) => {
    let params = {}
    if (countryId) {
      params.countryId = countryId
    }

    if (toDate) {
      params.toDate = moment(toDate).format('DD-MM-YYYY')
    }
    if (fromDate) {
      params.fromDate = moment(fromDate).format('DD-MM-YYYY')
    }
    const { data } = await dispatch(getPublicUniversityApplicationsData(params))
    if (data) {
      setMarketingUserApplicationRecord(data.data)
      setCountryStatusArr(data.countryStatusArr)
    }
  }

  return (
    <div className='row  mt-4'>
      <div className='col-lg-12'>
        <div className='card'>

          <div className={'filter_box p-3'}>
            <div className='d-flex align-items-center'>
              <div className={'mt-2'}>
                <h6>Live Reporting List For - <b className={'colorPrimaryDark'}>{countryName ? countryName : null}</b>
                </h6>
              </div>
              <div className={'ml10'}>
                - <b
                className={'colorPrimaryDark'}>{marketingUserApplicationRecord.length > 0 && marketingUserApplicationRecord[0].currentWeekOfMonth}th </b> Week
                Of The Month
              </div>
              <div>
                <Col span={24} className={'mt10 mb10 ml10'}>
                  From: <b className={'colorPrimaryDark'}>{moment(fromDate).format('DD-MM-YYYY')}</b> - To: <b
                  className={'colorPrimaryDark'}>{moment(toDate).format('DD-MM-YYYY')}</b>
                </Col>
              </div>
            </div>
            <Row className={'mt5'} gutter={16}>
              <Col md={6} sm={6} xs={6} lg={6}>
                <RangePicker
                  defaultValue={[toDate, fromDate]}
                  onChange={(val) => {
                    setFromDate(val[0])
                    setToDate(val[1])
                  }}
                  ranges={dateFilterRange}
                />
              </Col>
              <Col span={6}>
                <Button onClick={() => loadUserReport(selectUser)} className={'roundBtn'}>Search</Button>
              </Col>
            </Row>

          </div>
          <table className={'liveReportingTable w-100 table table-bordered table-striped'} border='1'
                 style={{ textAlign: 'left' }}>
            <thead>
            <tr>
              <th className={'liveReportingTableHead'}>Name</th>
              <th className={'liveReportingTableHead'}>Visits</th>
              <th className={'liveReportingTableHead'}>Expense</th>
              <th className={'liveReportingTableHead'}>Exp./visit</th>
              <th className={'liveReportingTableHead'}>Applications</th>
              {countryStatusArr && countryStatusArr.length ? countryStatusArr.map((item) => {
                return (
                  <th className={'liveReportingTableHead'} key={item}>{item}</th>
                )
              }) : null}

              <th className={'liveReportingTableHead'}>Visa</th>
              <th className={'liveReportingTableHead'}>Monthly Visits till Date</th>
            </tr>
            </thead>
            <tbody>
            {marketingUserApplicationRecord.map((item) => (
              <tr>

                <td className={'liveReportingTableData'}>
                  <div className={'d-flex center'}
                       style={{ marginLeft: 0 }}
                  >
                    <div>
                      <Avatar
                        size={30}
                        style={{
                          marginTop: 10,
                          marginLeft: 15,
                          marginRight: 15
                        }}
                        src={item && item.UserImage ? item.UserImage : '../dist/img/user.png'}
                      />
                    </div>
                    <b className={'mt-3'}>
                      {item.userName}
                    </b>
                  </div>
                </td>

                <td className={'liveReportingTableData'}>
                  <ColumnComponent
                    current={item.currentWeekCountOfMeetings}
                    previous={item.previousWeekCountOfMeetings}
                    percentage={item.percentageChangeOfMeetingsInWeek}
                  />
                </td>

                <td className={'liveReportingTableData'}>
                  <ColumnComponent
                    current={item.currentWeekCountOfExpense.toFixed(2)}
                    previous={item.previousWeekCountOfExpense.toFixed(2)}
                    percentage={item.percentageChangeOfExpenseInWeek}
                  />
                </td>

                <td className={'liveReportingTableData'}>
                  {item.currentWeekCountOfMeetings != 0
                    ? (item.currentWeekCountOfExpense / item.currentWeekCountOfMeetings).toFixed(2)
                    : 0}
                </td>


                <td className={'liveReportingTableData'}>
                  <ColumnComponent
                    current={item.offerReceivedCount}
                    previous={item.previousOfferReceivedCount}
                    percentage={item.percentageChangeOfOfferReceived}
                  />
                </td>
                {countryStatusArr && countryStatusArr.length ? countryStatusArr.map((statusField) => {
                  let obj = item[statusField]
                  return (
                    <td className={'liveReportingTableData'}>
                      <ColumnComponent
                        current={obj.current}
                        previous={obj.previous}
                        percentage={obj.percentage}
                      />
                    </td>
                  )
                }) : null}
                <td className={'liveReportingTableData'}>
                  <ColumnComponent
                    current={item.visaApproved}
                    previous={item.previousVisaApproved}
                    percentage={item.percentageChangeOfVisaApproved}
                  />
                </td>
                <td className={'liveReportingTableData'}>
                  <ColumnComponent
                    current={item.currentMonthCountOfMeetings}
                    previous={item.previousMonthCountOfMeetings}
                    percentage={item.percentageChangeOfMeetingsInMonth}
                  />
                </td>
              </tr>

            ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  activeUser: global.activeUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveReportingList)
