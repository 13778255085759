import {push} from 'connected-react-router'
import _ from 'lodash'
import Path from 'path-parser'
import Dashboard from './containers/dashboard/views/dashboard'
import AssignIntake from './containers/Intakes/AssignIntakeBackup'
import AddCountry from './containers/countries/views/add'
import AddCourse from './containers/course/views/add'
import AllCourse from './containers/course/views/all'
import EditCourse from './containers/course/views/edit'
import StudentWithdrawals from './containers/student/views/StudentWithdrawals'
import AddUniversity from './containers/university/views/add'
import MarketingAgentList from './containers/users/views/MarketingAgentList'
import MarketingUserAssignAgentList from './containers/users/views/MarketingUserAssignAgentList'
import AddUsers from './containers/users/views/add'
import AddMarketingManager from './containers/users/views/addMarketingManager'
import AddSubAgent from './containers/users/views/addSubAgent'
import AllSubAgent from './containers/users/views/allSubAgent'
import ApprovedAgentsList from './containers/users/views/approvedAgentsList'
import EditUser from './containers/users/views/edit'
import EditMarketingUser from './containers/users/views/editMarketingUser'
import EditSubAgent from './containers/users/views/editSubAgent'
import ApplicationProfile from './containers/applications/views/applicationProfile/profile'
import SingleApplication from './containers/applications/views/singleApplication'
import AddCampus from './containers/campus/views/add'
import ListAllCampus from './containers/campus/views/all'
import EditCampus from './containers/campus/views/edit'
import AddCity from './containers/city/views/add'
import ListAllCities from './containers/city/views/all'
import EditCity from './containers/city/views/edit'
import AllMarketingAgents from './containers/closeDeal/views/all'
import AllCountries from './containers/countries/views/all'
import EditCountry from './containers/countries/views/edit'
import AddFeedback from './containers/feedback/views/add'
import AllFeedback from './containers/feedback/views/all'
import ChangePassword from './containers/login/changePassword'
import LogoutComponent from './containers/login/logout'
import UpdatePassword from './containers/login/updatePassword'
import AllPendency from './containers/pendency/views/all'
import AddPrivateMessage from './containers/privateMessage/views/add'
import AllPrivateMessage from './containers/privateMessage/views/all'
import AllSampleDocument from './containers/sampleDocument/views/all'
import AddState from './containers/state/views/add'
import ListAllStates from './containers/state/views/all'
import EditState from './containers/state/views/edit'
import AddStudent from './containers/student/views/add'
import AllStudent from './containers/student/views/all'
import EditStudent from './containers/student/views/edit'
import FutureTasks from './containers/task/views/futureTasks'
import ListAllUniversities from './containers/university/views/all'
import EditUniversity from './containers/university/views/edit'
import ReferralComponent from '../src/containers/referrals/views/index'
import Reporting from '../src/containers/reporting/views/index'
import AgentProfile from './containers/AgentProfile'
import AllCourseCategory from './containers/CourseCategory/views/all'
import Leads from './containers/DailyReporting/views/leads'
import AustraliaOffShoreList from './containers/OffShoreAustralia/views/index'
import IndexAustralia from './containers/OffShoreAustralia/views/indexAustralia'
import InsuranceList from './containers/StudentInsurance/InsuranceList'
import StudentInsurance from './containers/StudentInsurance/StudentInsurance'
import AddTarget from './containers/addtarget/views'
import EditTarget from './containers/addtarget/views/edit'
import TargetList from './containers/addtarget/views/targetList'
import AssessmentApplications from './containers/applications/assessment/assessmentApplications'
import CasaApplicationList from './containers/applications/directUniversities/casaApplicationList'
import ApplicationStudentList from './containers/applications/student/studentList'


import AllApplicationsList from './containers/applications/views/allApplicationsList'
import DirectUniversityApplication from './containers/applications/views/directUniversityApplication'
import KasaApplicationList from './containers/applications/views/kasaApplicationList'
import LesterUniversityApplication from './containers/applications/views/lesterApplicationList'
import OshcApplications from './containers/applications/views/oshcApplications'
import UkTuitionApplications from './containers/applications/views/ukTuitionApplications'
import AddBanners from './containers/banners/view'
import Banners from './containers/banners/view/list'
import SendMailToAgents from './containers/broadCastMail/views/add'
import ChatBox from './containers/chatBox/views'
import CommissionStructure from './containers/commission/views/CommissionStructure'
import AllCommission from './containers/commission/views/all'
import {default as commissionAgentList} from './containers/commission/views/commissionAgentList'
import CompanyProfile from './containers/company/companyProfile'
import AddCompany from './containers/company/views/add'
import AllCompany from './containers/company/views/all'
import EditCompany from './containers/company/views/edit'
import counsellorDashboard from './containers/counsellorDashboard/view/profile'
import EvaluateProfile from './containers/evaluateProfile'
import AllNewsEnquiryList from './containers/news/views/enquiryList'
import ReportingApplicationList from './containers/reporting/views/reportingApplicationList'
import ReportingList from './containers/reporting/views/reportingList'
import AddScheduleMeeting from './containers/scheduleMetting/views/add'
import AllScheduleMeeting from './containers/scheduleMetting/views/all'
import AddMeetings from './containers/scheduleMetting/views/meetings'
import QuickAddStudent from './containers/student/quick/add'
import QuickAddAllStudent from './containers/student/quick/all'
import studentAddNewApplication from './containers/student/views/studentAddNewApplication'
import WithdrawalStudent from './containers/student/views/withdrawalStudent'
import AddTrustedStory from './containers/trustedStory/views'
import TrustedStoryList from './containers/trustedStory/views/list'
import AllUniversityCommission from './containers/universityCommission/views'
import AgentCommissionList from './containers/universityCommission/views/agentCommissionList'
import OldAgentCommissionList from './containers/universityCommission/views/oldAgentCommissionList'
import ReceivedCommissionList from './containers/universityCommission/views/receivedCommissionList'
import AddUpdates from './containers/updates/views'
import UpdatesList from './containers/updates/views/list'
import AllAgents from './containers/users/list/allAgents'
import AllBranchManagers from './containers/users/list/allBranchManagers'
import AllBranchUsers from './containers/users/list/allBranchUsers'
import AllMarketingManagers from './containers/users/list/allMarketingManagers'
import AllMasters from './containers/users/list/allMasters'
import AllSubAgents from './containers/users/list/allSubAgents'
import AddMaster from './containers/users/masters/addMaster'
import Agreement from './containers/users/views/agreement'
import AllNotifications from './containers/users/views/allNotifications'
import AllManagersAndUsers from './containers/users/views/allUsers'
import UserBirthdayList from './containers/users/views/birthdayList'
import AddCounsellor from './containers/users/views/counsellor/addCounsellor'
import EditCounsellor from './containers/users/views/counsellor/editCounsellor'
import UserProfile from './containers/users/views/userProfile'
import AssessmentsWarnings from './containers/warningDepartment/views/assessments'
import WaitingToApplyWarnings from './containers/warningDepartment/views/waitingToApply'

import AgentFootFall from './containers/FootFall/views/agentFootFall'
import RequestForTrainingList from './containers/FrontendComponents/trainingRequest/list'
import OnlineAgentsList from './containers/OnlineAgents/views/onlineList'
import UserTarget from './containers/UserTarget/view/userTarget'
import WhatsNew from './containers/WhatsNew/view/list'
import AllAgentCommissionList from './containers/agentCommission/views/allAgentCommissionList'

import CanadaLoaApplicationList from './containers/applications/views/canadaLoaApplicationList'
import AllLesterApplicationsList from './containers/applications/views/lester/AllLesterApplicationsList'
import OxfordPendingInterviewList from './containers/applications/views/oxfordUniversity/oxfordPendingInterviewList'
import ApprovedWithdrawals from './containers/commissionWithdrawal/view/approvedWithdrawals'
import CommissionWithdrawal from './containers/commissionWithdrawal/view/list'
import DailyAgentReport from './containers/dailyAgentReport/view/list'
import DailyExpenseList from './containers/dailyExpense/views/all'
import ExpenseWithdrawalList from './containers/dailyExpense/views/expenseWithdrawlList'
import DailyExpenseListForUser from './containers/dailyExpense/views/listForMarketingUser'
import DirectReportingComponent from './containers/dashboard/marketingManagerDashboard/directReportingComponent'
import CanadaOnShoreList from './containers/onShoreCanada/views/index'
import MarketingReportsApplicationList from './containers/reporting/marketingReports/list'
import ReferredAgentCommission from './containers/universityCommission/views/referredAgentCommission'
import EventList from './containers/Events/EventList'
import CanadaDirectUniApp from './containers/applications/views/canadaDirectUniApp'
import AddExpense from './containers/dailyExpense/views/add'
import HelpComponent from './containers/dashboard/help'
import AddIndianCity from './containers/indianCity/view/add'
import CambieAgentsList from './containers/CambieApp/views/cambieAgents'
import AssignIntakeCampusWays from './containers/Intakes/AssignIntake'
import CanadaProgramList from './containers/Intakes/programList/canadaProgramList'
import UkProgramList from './containers/Intakes/programList/ukProgramList'
import UsaProgramList from './containers/Intakes/programList/usaProgramList'
import StudentDiversity from './containers/student/studentDiversity'
import AgentListForMarketingManagers from './containers/users/AgentListForMarketingManagers'

import ApplicationsList from './containers/applications/applicationListView/applicationsList'
import MarketingDashboardOld from './containers/dashboard/marketingDashboard'
import MarketingManagerDashboard from './containers/dashboardMarketing/marketingManager'
import MarketingManagerOldDashboard from './containers/dashboardMarketing/marketingManagerOldDashboard'
import MarketingUserDashboard from './containers/dashboardMarketing/marketingUser'
import OnShoreStudentList from './containers/student/components/onShoreStudentList'
import UnizHomeEnquiryList from './containers/unizHomeEnquiry/view/studentEnquiryList'
import IcefEventsList from './containers/IcefEvent/events/list'
import OnshoreCambieAppList from './containers/applications/cambieOnshoreApplicationList/onshoreCambieAppList'
import EnrollCompany from './containers/enrollCompany/views/add'
import AllEnrolledCompanies from './containers/enrollCompany/views/all'
import MainWithDrawalList from './containers/student/views/mainWithDrawalList'
import TransactionList from './containers/student/views/transactionList'
import YourReferralsList from './containers/student/views/yourRefferals'
import TaskManager from './containers/task/views/taskManager'
import TaskManager2 from './containers/task/views/taskManager2'
import TaskTesting from './containers/task/views/taskTesting'
import CanadaLocalOffice from './containers/onShoreCanada/views/canadaLocalOffice'
import OnshoreFcmtAppList from './containers/applications/fcmtOnshoreApplicationList/onshoreFcmtAppList'
import CanadaOnshoreProgramList from './containers/Intakes/programList/canadaOnshoreProgramList'
import IcefMeeting from './containers/IcefEvent/meetings/icefMeeting/icefMeeting'
import AddIcefEvents from './containers/IcefEvent/meetings/icefMeeting/addIcefEvents'
import AddParticipants from './containers/IcefEvent/meetings/icefMeeting/addParticipants'
import ParticipantsList from './containers/IcefEvent/meetings/icefMeeting/participantsList'

import AddComplaint from './containers/feedback/views/addFeedback'
import ComplaintList from './containers/feedback/views/allFeedback'

import EventRegistration from './containers/institutes/instituteEvents/eventRegistration'
import AllInstitutes from './containers/institutes/allInstitutes'
import InstituteEvents from './containers/student/eventList/instituteEvents'
import RegisteredEvents from './containers/student/eventList/registeredEvents'
import InstituteEventTable from './containers/institutes/instituteEvents/instituteEventTable'
import ContractList from './containers/contract/view/contractList'
import RoyalHighSchoolApplicationsList
    from './containers/applications/views/ciraHighSchool/royalHighSchoolApplicationsList'

import EventCalendar from './containers/counselling/eventCalendar'
import CounsellorProfile from './containers/counselling/counsellor-profile'
import AddCounselling from './containers/student/views/addCounselling'
import RoyalHighSchoolPendingLoaList
    from './containers/applications/views/CanadaRoyalHighSchool/royalHighSchoolPendingLoaList'
import AllRoyalHighSchoolApplicationsList
    from './containers/applications/views/CanadaRoyalHighSchool/allRoyalHighSchoolApplicationsList'
import AddGicPage from './containers/dashboard/gic/addGicPage'
import GicList from './containers/dashboard/gic/gicList'
import GicAccountFundedList from './containers/dashboard/gic/gicAccountFundedList'
import GicAccountOpenedList from './containers/dashboard/gic/gicAccountOpenedList'
import WithdrawalList from './containers/dashboard/gic/Withdrawals/views/list'
import CounsellingSessions from './containers/student/views/counsellingSessions'
import GicApprovedWithdrawals from './containers/gicWithdrawal/view/approvedGicWithDrawal'
import StudentCounsellingList from './containers/counselling/studentCounsellingList'
import CornerStoneApplicationList from './containers/DirectColleges/cornerStoneCollege/applicationsList'
import CornerStoneOfferApplicationList from './containers/DirectColleges/cornerStoneCollege/offerApplicationList'
import CornerStoneLoaRequestedList from './containers/DirectColleges/cornerStoneCollege/loaRequestedList'
import {adminRightUser, cambieOnshoreUserList, gicCommissionValue} from './components/_utils/appUtils'
import OtherUsers from './containers/users/list/otherUsers'
import DailyExpenseListForUserCanada from './containers/dailyExpenseCanada/views/listForMarketingUser'
import DailyExpenseListForCanada from './containers/dailyExpenseCanada/views/all'
import AddExpenseCanada from './containers/dailyExpenseCanada/views/add'
import AddPal from './containers/pal/view/uploadPal'
import StudentListCornetStone from './containers/pal/view/studentList'
import NationalCollegeApplicationList from './containers/DirectColleges/nationalCollege/applicationsList'
import NationalCollegeOfferApplicationList from './containers/DirectColleges/nationalCollege/offerApplicationList'
import NationalCollegeLoaRequestedList from './containers/DirectColleges/nationalCollege/loaRequestedList'
import AllRequestedList from './containers/closeDeal/views/allRequestedList'
import OnshoreApplicationsList from './containers/applications/onshoreApplications/applicationsList'
import LatestUpdate from './containers/latestUpdate/latestUpdates'
import AddLatestUpdate from './containers/latestUpdate/addLatestUpdate'
import InterestedRequestList from './containers/DirectColleges/cornerStoneCollege/components/interestedRequestList'
import AdvanceCommissionContract from './containers/users/advanceCommission/advanceCommissionContract'
import RequestCallBackList from './containers/Intakes/requestCallBack/requestCallBackList'
import FollowUpList from './containers/scheduleMetting/views/followupList'
import DashboardProfile from './containers/dashboard/views/dashboardProfile'
import AddComplaintDepartment from './containers/ComplaintDepartement/view/addComplaintDepartment'
import CadExpenseWithdrawalList from './containers/dailyExpenseCanada/views/expenseWithdrawlList'

import AddTeam from './containers/users/views/addTeam'
import AllTeam from './containers/users/views/allTeam'
import AddTravelPlan from './containers/travelPlan/addTravelPlan'
import RequestCallback from './containers/dashboard/list/requestCallbackList'
import InstituteStudentList from './containers/institutes/instituteStudentList'
import GicCommissionReport from './containers/gicCommissionReport/view/gicCommissionReportsList'
import AllTravelPlanList from './containers/travelPlan/allTravelPlanList'
import GicRequestCallback from './containers/dashboard/list/gicRequestCallback'
import OnShoreApplicationListForCommission
    from './containers/applications/onShoreApplicationForCommission/view/onShoreApplicationListForCommission'
import AllUniversityOnShoreCommission from './containers/universityCommission/onShoreCommission/index'
import ReceivedOnShoreCommissionList from './containers/universityCommission/onShoreCommission/receivedCommissionList'
import AgentOnShoreCommissionList from './containers/universityCommission/onShoreCommission/agentCommissionList'

import CornerstonePaidApplicationList from './containers/applications/cornerstoneCollege/cornerStonePaidApplicationList'
import CornerstonePendingForSubmissionApplicationList
    from './containers/applications/cornerstoneCollege/cornerStonePendingForSubmission'
import CornerstonePendingTTApplicationList
    from './containers/applications/cornerstoneCollege/cornerStonePendingTTApplicationList'
import AdvanceCommissionReqList from './containers/users/advanceCommission/advanceCommissionReqList'

import YourTravelPlan from './containers/travelPlan/yourTravelPlan'
import AllAgentCanadaOnShoreCommissionListComponent
    from './containers/agentCommission/views/allAgentCanadaOnshoreCommissionList'
import CanadaOnshoreWithdrawal from './containers/commissionWithdrawal/view/canadaOnshoreWithdrawals'
import CounsellingFollowUpList from './containers/counselling/counsellingFollowupList'

import VisaApprovedAppsList from './containers/DirectColleges/cornerStoneCollege/visaApporvedAppsList'
import OnshoreCambieCommissionList
    from './containers/applications/cambieOnshoreApplicationList/onShoreCambieCommissionList'
import CityWiseTravelPlan from './containers/travelPlan/travellingToAgentCityList'
import CanadaApplicationList from './containers/applications/canadaApplications/canadaApplicationsList'
import DraftApplicationList from './containers/applications/draftApplicationList/draftApplicationList'
import DownloadReport from './containers/home/downloadReport'
import SearchSingleApplication from './containers/applications/searchApplication/searchSingleApplication'
import CountryWiseCourseList from './containers/course/views/countryWiseCourseList'
import AgentCurrentWallet from './containers/agentCurrentWallet/index'
import DeactivatedUsers from './containers/deactivatedUsers/index'
import AgentListByDirectUniversity from './containers/agentListByDirectUniversity/index'
import UniversityWiseCommissions from './containers/universityWiseReport/index'
import BloomsburyInstituteApplications from './containers/applications/views/bloomsburyInstituteApplications'
import FcmtPendingApplicationList from './containers/applications/views/fcmtPendingApplicationList'
import AllFcmtApplicationsList from './containers/applications/views/AllFcmtApplicationsList'
import AllRoyalArtsApplicationsList from './containers/applications/views/RoyalArtsCollege/AllRoyalArtsApplicationsList'
import RegisteredAgents from './containers/users/agents/registeredAgents'
import LiveReporting from './containers/liveReporting/liveReporting'
import LiveReportingList from './containers/liveReporting/liveReportingList'
import UnVerifiedAgents from './containers/users/agents/unVerifiedAgents'
import OnlineTeamList from './containers/OnlineAgents/views/onlineTeamList'
import AllMarketingMeetings from './containers/scheduleMetting/marketingMeetings/all'
import CountryWiseStatusCount from './containers/eachCountryStatusListCount/eachCountryStatusListCount'
import CallbackList from './containers/Callback/views/callbackList'
import reeudoRoutes from "./reeudoRoutes";
import AllReactivationRequest from "./containers/login/reactivationRequestList";

let menu = [
    {
        path: '/dashboard',
        title: 'Dashboard',
        name: 'Dashboard',
        icon: 'dashboard',
        key: 'dashboard',
        homepage: true,
        component: Dashboard,
        authority: [
            'student',
            'admin',
            'agent',
            'institute',
            'subAgent',
            'onshoreManager',
            'branchManager',
            'branchUser',
            'master',
            'interviewer',
            'university',
            'userManager',
            'hr',
            'reception',
            'marketingManager',
            'onshoreCanada',
            'courseMaster',
            'cambieMarketingManager',
            'cambieBranchUser',
            'eventManager',
            'counsellingManager',
            'unizhomeManager',
            'gicManager',

            'cornerStoneManager',
            'nationalCollegeManager',
            'canadaOnshoreAdmin',
            'cambieManager',
            'gicMarketing',
            'onshoreAccountant',
            'team',
            'cambieAccountant',
            'cambieDirector',
            'cambieReception',
            'itTeam',
            'directHead'
        ]
    },

    {
        path: '/dashboard2',
        title: 'Dashboard 2',
        name: 'Dashboard 2',
        icon: 'dashboard',
        key: 'oldDashboard',
        homepage: true,
        component: MarketingManagerOldDashboard,
        authority: ['marketingManager']
    },

    {
        path: '/marketing-dashboard2',
        title: 'Dashboard 2',
        name: 'Dashboard 2',
        icon: 'dashboard',
        key: 'oldMarketingUserDashboard',
        homepage: true,
        component: MarketingDashboardOld,
        authority: ['branchUser']
    },
    {
        path: '/onShore-accountant-dashboard',
        title: 'Onshore Canada LOA/Receipt',
        name: 'Onshore Canada LOA/Receipt',
        icon: 'dashboard',
        key: 'onshoreAccountantDashboard',
        homepage: true,
        component: OnShoreApplicationListForCommission,
        authority: ['onshoreAccountant']
    },
    {
        path: '/dashboardProfile',
        title: 'Dashboard Profile',
        name: 'Dashboard Profile',
        icon: 'dashboard',
        key: 'dashboardProfile',
        homepage: true,
        dontShowOnMenu: true,
        component: DashboardProfile
    },

    /*
    {
      path: '/team-marketing-dashboard',
      title: 'Team Dashboard',
      name: 'Team Dashboard',
      icon: 'dashboard',
      key: 'teamMarketingUserDashboard',
      homepage: true,
      component: TeamMarketingDashboard,
      authority: ['branchUser']
    },
  */

    {
        path: '/subAgent',
        name: 'Our Users',
        title: 'Our Users',
        icon: 'user',
        key: 'subAgent',
        dontShowOnMenu: true,
        authority: ['agent'],
        children: [
            {
                path: '/subAgent/add',
                name: 'Add User',
                title: 'Add User',
                key: 'addSubAgent',
                component: AddSubAgent,
                authority: ['agent']
            },
            {
                path: '/subAgent/all',
                name: 'All User',
                title: 'All User',
                key: 'allSubAgent',
                component: AllSubAgent,
                authority: ['agent']
            },
            {
                path: '/subAgent/edit/:id',
                name: 'Edit User',
                title: 'Edit User',
                key: 'editSubAgent',
                dontShowOnMenu: true,
                component: EditSubAgent,
                authority: ['admin', 'agent']
            }
        ]
    },

    {
        path: '/users',
        title: 'Users',
        name: 'Users',
        icon: 'user',
        key: 'user',
        homepage: true,
        authority: [
            ...adminRightUser,
            'agent',
            'branchManager',
            'branchUser',
            'master',
            'userManager'
        ],
        children: [
            {
                path: '/users/all-branch-manager',
                name: 'All Branch Manager',
                title: 'All Branch Manager',
                key: 'allBranchManagers',
                component: AllBranchManagers,
                authority: [...adminRightUser, 'userManager']
            },
            {
                path: '/users/all-marketing-manager',
                name: 'All Marketing Manager',
                title: 'All Marketing Manager',
                key: 'allMarketingManagers',
                component: AllMarketingManagers,
                authority: [...adminRightUser, 'userManager']
            },
            {
                path: '/users/all-branch-user',
                name: 'All Branch User',
                title: 'All Branch User',
                key: 'allBranchUsers',
                component: AllBranchUsers,
                authority: [...adminRightUser, 'userManager']
            },
            {
                path: '/users/all-masters',
                name: 'All Masters',
                title: 'All Masters',
                key: 'allMasters',
                component: AllMasters,
                authority: [...adminRightUser, 'userManager']
            },

            {
                path: '/users/all-agents',
                name: 'All Agents',
                title: 'All Agents',
                key: 'allAgents',
                component: AllAgents,
                restrict: true,
                authority: [
                    ...adminRightUser,
                    'agent',
                    'subAgent',
                    'branchManager',
                    'branchUser',
                    'master',
                    'userManager'
                ]
            },
            {
                path: '/users/all-sub-agents',
                name: 'All Sub Agents',
                title: 'All Sub Agents',
                key: 'allSubAgents',
                restrict: true,
                component: AllSubAgents,
                authority: [
                    ...adminRightUser,
                    'agent',
                    'subAgent',
                    'branchManager',
                    'branchUser',
                    'master',
                    'userManager'
                ]
            },
            {
                path: '/users/other-users',
                name: 'Other Users',
                title: 'Other Users',
                key: 'otherUsers',
                component: OtherUsers,
                authority: [...adminRightUser, 'userManager']
            }
        ]
    },

    {
        path: '/expand-your-team',
        title: 'Expand Your Team',
        name: 'Expand Your Team',
        icon: 'user',
        key: 'expandYourTeam',
        homepage: true,
        authority: ['agent'],
        children: [
            {
                path: '/expand-your-team/allSubAgent',
                name: 'All Sub Agents',
                title: 'All Sub Agents',
                key: 'allSubAgent',
                component: AllSubAgent,
                authority: ['agent']
            },
            {
                path: '/expand-your-team/allTeams',
                name: 'All Teams',
                title: 'All Teams',
                key: 'teams',
                component: AllTeam,
                authority: ['agent']
            },
            {
                path: '/addTeam',
                name: 'Add Team',
                title: 'Add Team',
                key: 'addTeam',
                component: AddTeam,
                authority: ['agent'],
                dontShowOnMenu: true
            }
        ]
    },

    {
        path: '/gic',
        title: 'Gic',
        name: `Open Gic Acc-Get ${gicCommissionValue}`,
        icon: 'gic',
        key: 'gic',
        newLink: true,
        homepage: true,
        restrict: true,
        authority: ['admin', 'agent', 'gicManager', 'branchManager'],
        children: [
            {
                path: '/gic/add-gic',
                name: 'Add GIC Request',
                title: 'Add GIC Request',
                key: 'addGicRequest',
                component: AddGicPage,
                authority: ['agent']
            },
            {
                path: '/gic/gic-Request-list',
                name: 'GIC Requested',
                title: 'GIC list',
                key: 'gicRequested',
                component: GicList,
                authority: ['admin', 'agent', 'gicManager', 'branchManager']
            },
            {
                name: 'GIC Account Opened',
                path: '/gic/gic-account-open-list',
                key: 'gicAccountOpened',
                title: 'GIC Account Opened',
                component: GicAccountOpenedList,
                authority: ['admin', 'agent', 'gicManager', 'branchManager']
            },
            {
                name: 'GIC Account Funded',
                path: '/gic/gic-account-funded-list',
                key: 'gicApproved',
                title: 'GIC list',
                component: GicAccountFundedList,
                authority: ['admin', 'agent', 'gicManager', 'branchManager']
            },
            {
                name: 'GIC Commission Withdrawal',
                path: '/gic/gic-withdrawal-list',
                key: 'withdrawalList',
                title: 'GIC list',
                component: WithdrawalList,
                authority: ['admin', 'agent', 'gicManager', 'branchManager']
            }
        ]
    },
    {
        dontShowOnMenu: true,
        path: '/user/add-counsellor',
        name: 'Add Counsellor',
        title: 'Add Counsellor',
        icon: 'user',
        key: 'addCounsellor',
        component: AddCounsellor,
        authority: ['admin', 'userManager']
    },
    {
        path: '/canada-Onshore',
        name: 'Apply for Canada Onshore',
        icon: 'user',
        key: 'canadaOnShoreCourseList',
        newLink: true,
        component: CanadaOnshoreProgramList,
        restrict: true,
        authority: [
            'admin',
            'agent',
            'subAgent',
            'branchManager',
            'branchUser',
            'userManager',
            'marketingManager',
            'hr'
        ]
    },
    {
        homepage: true,
        icon: 'user',
        path: '/add-student',
        name: 'Add Student',
        title: 'Add Student',
        component: AddStudent,
        restrict: true,
        key: 'addStudent',
        authority: [
            'agent',
            'subAgent',
            'branchManager',
            'branchUser',
            'marketingManager',
            'canadaOnshoreAdmin',
            'team',
            ...adminRightUser
        ]
    },
    {
        homepage: true,
        path: '/students',
        name: 'Students',
        title: 'Students',
        component: AllStudent,
        icon: 'user',
        key: 'allStudent',
        restrict: true,
        authority: [
            ...adminRightUser,
            'agent',
            'subAgent',
            'team',
            'branchManager',
            'branchUser',
            'marketingManager',
            'userManager',
            'canadaOnshoreAdmin'
        ]
    },
    {
        homepage: true,
        path: '/direct-pending-students',
        name: 'Direct Pending Students',
        title: 'Direct Pending Students',
        component: OnShoreStudentList,
        icon: 'user',
        key: 'pendingOnShoreApplications',
        restrict: true,
        authority: ['branchManager', 'branchUser']
    },
    {
        path: '/user',
        name: 'Users',
        icon: 'user',
        title: 'Users',
        dontShowOnMenu: true,
        key: 'Users',
        authority: ['admin', 'branchManager', 'userManager', 'hr'],
        children: [
            {
                path: '/user/add-user',
                name: 'Add User',
                title: 'Add User',
                key: 'addUser',
                component: AddUsers,
                authority: ['admin', 'branchManager', 'userManager', 'hr']
            },
            {
                path: '/user/add-marking-user',
                name: 'Add Marketing User',
                title: 'Add Marketing User',
                key: 'addMarketingUser',
                component: AddMarketingManager,
                authority: ['admin', 'userManager']
            },
            {
                path: '/user/add-master',
                name: 'Add Master',
                title: 'Add Master',
                key: 'addMaster',
                component: AddMaster,
                authority: ['admin', 'userManager']
            },
            {
                path: '/edit-markingUser/:id',
                name: 'Edit Marketing User',
                title: 'Edit Marketing User',
                key: 'editMarketingUser',
                component: EditMarketingUser,
                authority: ['admin', 'userManager']
            },

            {
                path: '/user/edit-user/:id',
                name: 'Edit User',
                title: 'Edit User',
                dontShowOnMenu: true,
                key: 'editUser',
                component: EditUser,
                authority: ['admin', 'branchManager']
            },
            {
                path: '/edit-counsellor/:id',
                name: 'Edit Counselling',
                title: 'Edit Counselling',
                dontShowOnMenu: true,
                key: 'editCounsellor',
                component: EditCounsellor,
                authority: ['admin', 'userManager']
            }
        ]
    },

    {
        path: '/feedback',
        name: 'Feedback & Complaints',
        icon: 'user',
        title: 'Feedback & Complaints',
        dontShowOnMenu: true,
        key: 'feedback',
        authority: [
            'admin',
            'branchManager',
            'branchUser',
            'agent',
            'subAgent',
            'userManager',
            'student'
        ],
        children: [
            {
                path: '/feedback/add',
                name: 'Add Feedback',
                title: 'Add Feedback & Complaints',
                key: 'addFeedback',
                component: AddFeedback,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager',
                    'student'
                ]
            },
            {
                path: '/feedback/all',
                name: 'All Feedback & Complaints',
                title: 'All Feedback & Complaints',
                key: 'allFeedback',
                component: AllFeedback,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager',
                    'student'
                ]
            }
        ]
    },

    {
        path: '/notification/all',
        name: `AllNotifications`,
        icon: 'bell',
        key: 'allNotifications',
        homepage: true,
        dontShowOnMenu: true,
        component: AllNotifications,
        authority: ['admin', 'agent', 'subAgent', 'branchManager', 'branchUser']
    },
    {
        path: '/student',
        name: 'Students',
        icon: 'home',
        key: 'student',
        dontShowOnMenu: true,
        authority: [
            'admin',
            'agent',
            'subAgent',
            'branchManager',
            'branchUser',
            'marketingManager'
        ],
        children: [

            {
                path: '/student/quick_add',
                name: 'Add Quick Student',
                title: 'Add Quick Student',
                dontShowOnMenu: true,
                component: QuickAddStudent,
                key: 'quickAddStudent',
                authority: ['admin']
            },

            {
                path: '/student/all-quick-added',
                name: 'Quick Added All Students',
                title: 'Quick Added All Students',
                dontShowOnMenu: true,
                component: QuickAddAllStudent,
                key: 'quickAddAllStudent',
                authority: ['admin', 'branchManager']
            },
            {
                path: '/app/edit-student/:id',
                name: 'Edit Student',
                title: 'Edit Student',
                key: 'editStudent',
                dontShowOnMenu: true,
                component: EditStudent,
                authority: ['admin', 'branchManager', 'branchUser', 'agent', 'subAgent']
            },
            {
                path: '/student/application',
                name: 'Application Profile',
                title: 'Application Profile',
                dontShowOnMenu: true,
                component: ApplicationProfile,
                key: 'applicationProfile',
                authority: ['admin', 'branchManager', 'branchUser', 'userManager', 'canadaOnshoreAdmin', 'itTeam', 'directHead']
            },
            {
                path: '/student/studentAddApplication',
                name: 'Add Application',
                title: 'Add Application',
                dontShowOnMenu: true,
                component: studentAddNewApplication,
                key: 'studentAddNewApplication',
                authority: ['student']
            },
            {
                path: '/student/referrals',
                name: 'Refer & Earn',
                title: 'Refer & Earn',
                dontShowOnMenu: true,
                component: ReferralComponent,
                key: 'referralComponent',
                authority: ['student']
            }

        ]
    },
    {
        path: '/tasks-list',
        name: 'Tasks',
        // dontShowOnMenu: true,
        key: 'tasksList',
        restrict: true,
        newLink: true,
        /*authority: [
      'admin',
      'userManager',
      'branchManager',
      'marketingManager',
      'branchUser',
      'canadaOnshoreAdmin',

      'cambieBranchUser',
      'eventManager',
      'counsellingManager',
      'unizhomeManager',
      'gicManager',

      'cornerStoneManager',
      'cambieManager',
      'onshoreAccountant',
      'cambieAccountant',
      'cambieDirector',
      'cambieReception'

    ],*/
        component: TaskManager
    },
    {

        path: '/followupList',
        name: 'Followup List',
        title: 'Followup List',
        key: 'followupList',
        component: FollowUpList,
        authority: ['branchUser'],
        dontShowOnMenu: false
    },
    {
        path: '/travel-plan-list',
        name: 'Travel Plan',
        key: 'travelPlanList',
        restrict: true,
        newLink: true,
        authority: ['admin', 'branchUser', 'gicMarketing', 'marketingManager'],
        component: AllTravelPlanList
    },
    {
        path: '/student/referral-code',
        name: 'Referral Code',
        title: 'Referral Code',
        dontShowOnMenu: false,
        component: ReferralComponent,
        key: 'referralComponent',
        authority: ['student']
    },
    {
        path: '/your-referrals',
        name: `Your Referrals`,
        icon: 'unordered-list',
        key: 'yourReferrals',
        dontShowOnMenu: false,
        homepage: true,
        component: YourReferralsList,
        authority: ['student']
    },
    {
        path: '/marketingAgent',
        name: 'Agents',
        icon: 'user',
        title: 'Agents',
        key: 'marketingAgent',
        restrict: true,
        component: MarketingAgentList,
        authority: ['branchManager', 'marketingManager']
    },
    {
        path: '/marketing-assign-agents',
        name: 'Assigned Agents',
        icon: 'user',
        title: 'Agents',
        key: 'marketingUserAssignAgentList',
        restrict: true,
        component: MarketingUserAssignAgentList,
        authority: ['branchUser']
    },

    {
        path: '/company',
        name: 'Company',
        icon: 'home',
        key: 'company',
        dontShowOnMenu: true,
        authority: ['admin'],
        children: [
            {
                path: '/company/add',
                name: 'Add Company',
                title: 'Add Company',
                component: AddCompany,
                key: 'addCompany',
                authority: ['admin']
            },
            {
                path: '/company/all',
                name: 'All Company',
                title: 'All Company',
                component: AllCompany,
                key: 'allCompany',
                authority: ['admin']
            },
            {
                path: '/app/edit-company/:id',
                name: 'Edit Company',
                title: 'Edit Company',
                key: 'editCompany',
                dontShowOnMenu: true,
                component: EditCompany,
                authority: ['admin']
            },
            {
                path: '/company/profile',
                name: 'Company Profile',
                title: 'Company Profile',
                dontShowOnMenu: true,
                component: CompanyProfile,
                key: 'companyProfile',
                authority: ['admin']
            }
        ]
    },
    {
        path: '/all-applications-list',
        name: 'All Applications List',
        title: 'All Applications List',
        icon: 'home',
        key: 'allApplicationsList',
        restrict: true,
        component: AllApplicationsList,
        authority: ['admin', 'branchManager', 'userManager']
    },
    {
        path: '/commission-withdrawal-list',
        name: 'Commission Withdrawal',
        title: 'Commission Withdrawal',
        component: CommissionWithdrawal,
        key: 'commissionWithdrawal',
        restrict: true,
        icon: 'user',
        newLink: true,
        authority: ['admin']
    },
    {
        path: '/bloomsbury-applications-list',
        name: 'Bloomsbury Applications List',
        title: 'Bloomsbury Applications List',
        icon: 'home',
        key: 'bloomsburyInstituteApplications',
        dontShowOnMenu: true,
        newLink: true,
        component: BloomsburyInstituteApplications,
        authority: [
            'admin',
            'branchManager',
            'branchUser',
            'agent',
            'subAgent',
            'userManager',
            'marketingManager'
        ]
    },
    {
        path: '/application',
        name: 'Application',
        icon: 'home',
        key: 'application',
        dontShowOnMenu: true,
        authority: [
            'admin',
            'agent',
            'subAgent',
            'userManager',
            'branchUser',
            'marketingManager',
            'branchManager'
            /*'cambieBranchUser',
      'cambieMarketingManager'*/
        ],
        children: [
            {
                path: '/application/view',
                name: 'View Application',
                title: 'View Application',
                key: 'singleApplication',
                component: SingleApplication,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'team',
                    'userManager',
                    'hr',
                    'student',
                    'cambieMarketingManager',
                    'directHead',
                    ...cambieOnshoreUserList
                ]
            },
            {
                path: '/applicationsList',
                name: 'Applications List',
                title: 'Applications List',
                key: 'applicationsList',
                component: ApplicationsList,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager',
                    'hr',
                    'canadaOnshoreAdmin',
                    'cambieManager',
                    'team'
                ]
            },

            {
                path: '/application-students',
                name: 'Application Student List',
                title: 'Application Student List',
                key: 'applicationsStudentList',
                component: ApplicationStudentList,
                authority: [
                    ...adminRightUser,
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager',
                    'hr'
                ]
            },


            {
                path: '/directUniversity-applications-list',
                name: 'Direct University Applications List',
                title: 'Direct University List',
                key: 'directUniversityApplicationList',
                component: DirectUniversityApplication,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager'
                ]
            },
            {
                path: '/lesterUniversity-applications-list',
                name: 'Lester University Applications List',
                title: 'Lester University List',
                key: 'lesterUniversityApplicationList',
                component: LesterUniversityApplication,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager'
                ]
            },
            {
                path: '/kasa-applications-list',
                name: 'Kasa University Applications List',
                title: 'Kasa University List',
                key: 'kasaApplicationList',
                component: KasaApplicationList,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager'
                ]
            },
            {
                path: '/casa-applications-list',
                name: 'Casa University Applications List',
                title: 'Casa University List',
                key: 'casaApplicationList',
                component: CasaApplicationList,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager'
                ]
            },

            {
                path: '/cira-high-school-applications-list',
                name: 'CIRA High School Applications List',
                title: 'CIRA High School Applications List',
                key: 'ciraHighSchoolApplicationList',
                component: RoyalHighSchoolApplicationsList,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager'
                ]
            },

            {
                path: '/cornerstone-applications-list',
                name: 'Cornerstone Applications List',
                title: 'Cornerstone Applications List',
                key: 'cornerStoneApplicationList',
                component: CornerStoneApplicationList,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager'
                ]
            },
            {
                path: '/national-college-applications-list',
                name: 'National College Applications List',
                title: 'National College Applications List',
                key: 'nationalCollegeApplicationList',
                component: NationalCollegeApplicationList,
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'subAgent',
                    'userManager'
                ]
            }

        ]
    },

    {
        path: '/fcmt-pendingLOA-applications-list',
        name: 'FCMT Approve-LOA',
        title: 'FCMT PendingLOA',
        key: 'fcmtPendingApplicationsList',
        component: FcmtPendingApplicationList,
        icon: 'unordered-list',
        restrict: true,
        authority: ['admin', 'branchUser']
    },

    {
        path: '/cornerstone',
        name: 'Cornerstone College',
        icon: 'database',
        key: 'cornerstone',
        homepage: true,
        restrict: true,
        authority: ['admin', 'branchUser', 'marketingManager', 'branchManager'],
        children: [
            {
                path: '/cornerstone/pending-for-submission-application-list',
                name: 'Pending For Submissions',
                title: 'File Pending For Submissions Applications',
                key: 'cornerstoneFilePendingForSubmissions',
                component: CornerstonePendingForSubmissionApplicationList,
                icon: 'unordered-list',
                restrict: true,
                authority: ['admin', 'branchUser', 'marketingManager', 'branchManager']
            },
            {
                path: '/cornerstone/upload-tt-applications-list',
                name: 'Pending TT',
                title: 'Pending TT Cornerstone',
                key: 'cornerstoneUploadTT',
                component: CornerstonePendingTTApplicationList,
                icon: 'unordered-list',
                restrict: true,
                authority: ['admin', 'branchUser', 'marketingManager', 'branchManager']
            },
            {
                path: '/cornerstone/paid-tt-applications-list',
                name: 'Paid/Updated TT',
                title: 'Paid/Updated TT cornerstone',
                key: 'cornerstonePaidTT',
                component: CornerstonePaidApplicationList,
                icon: 'unordered-list',
                restrict: true,
                authority: ['admin', 'branchUser', 'marketingManager', 'branchManager']
            }
        ]
    },

    /* {
    path: '/oxford-pending-interview-list',
    name: 'Oxford Pending Interviews',
    title: 'Oxford Pending Interviews',
    key: 'oxfordPendingInterviewList',
    component: OxfordPendingInterviewList,
    icon: 'unordered-list',
    restrict: true,
    authority: ['admin', 'branchUser']
  },*/
    /* {
     path: '/oxford-draft-applications-list',
     name: 'Oxford Approve-Draft',
     title: 'Oxford PendingDraft',
     key: 'oxfordPendingApplicationsList',
     component: OxfordPendingApplicationList,
     icon: 'unordered-list',
     restrict: true,
     authority: ['admin', 'branchUser']
   },
   {
     path: '/oxford-interview-applications-list',
     name: 'Oxford Interview Applications',
     title: 'Oxford Interview Applications',
     key: 'oxfordInterviewApplications',
     component: OxfordInterviewApplications,
     icon: 'unordered-list',
     restrict: true,
     authority: ['admin', 'branchUser', 'userManager']
   },*/


    {
        path: '/royal-high-school-pending-loa-list',
        name: 'Royal High School Approve-LOA',
        title: 'Royal High School Pending LOA',
        key: 'royalHighSchoolPendingLoaList',
        component: RoyalHighSchoolPendingLoaList,
        icon: 'unordered-list',
        restrict: true,
        authority: ['admin', 'branchUser']
    },

    {
        path: '/all-lester-applications-list',
        name: 'All Lester Applications',
        title: 'All Lester Applications',
        key: 'allLesterApplicationsList',
        component: AllLesterApplicationsList,
        icon: 'unordered-list',
        restrict: true,
        authority: ['admin', 'branchUser']
    },
    {
        path: '/all-royal-arts-applications-list',
        name: 'CIRA All Applications',
        title: 'CIRA All Applications',
        key: 'allRoyalArtsApplicationsList',
        component: AllRoyalArtsApplicationsList,
        icon: 'unordered-list',
        restrict: true,
        authority: [...adminRightUser, 'branchUser', 'cambieManager', 'directHead']
    },
    {
        path: '/all-fcmt-applications-list',
        name: 'All FCMT Applications',
        title: 'All FCMT Applications',
        key: 'allFcmtApplicationsList',
        component: AllFcmtApplicationsList,
        icon: 'unordered-list',
        restrict: true,
        authority: ['itTeam', 'directHead']
    },
    {
        path: '/all-cira-high-school-applications-list',
        name: 'CIRA High School Applications',
        title: 'CIRA High School Applications',
        key: 'allRoyalHighSchoolApplicationsList',
        component: AllRoyalHighSchoolApplicationsList,
        icon: 'unordered-list',
        restrict: true,
        authority: ['admin', 'branchUser']
    },
    {
        path: '/all-oshc-applications-list',
        name: 'All OSHC Applications',
        title: 'All OSHC Applications',
        key: 'oshcApplications',
        component: OshcApplications,
        icon: 'unordered-list',
        dontShowOnMenu: true,
        restrict: true,
        authority: ['admin', 'branchUser']
    },
    {
        path: '/uk-tuition-fees-applications',
        name: 'UK Tuition Applications',
        title: 'UK Tuition Applications',
        key: 'ukTuitionFeeApplications',
        component: UkTuitionApplications,
        icon: 'unordered-list',
        dontShowOnMenu: true
    },
    {
        path: '/withdrawal',
        name: `Withdrawal`,
        icon: 'unordered-list',
        key: 'withdrawal',
        dontShowOnMenu: true,
        homepage: true,
        component: WithdrawalStudent,
        authority: ['admin', 'agent', 'subAgent']
    },
    {
        path: '/student-withdrawal',
        name: `Your Withdrawals`,
        icon: 'unordered-list',
        key: 'studentWithdrawal',
        dontShowOnMenu: false,
        homepage: true,
        component: StudentWithdrawals,
        authority: ['student']
    },
    {
        path: '/student-withdrawal-list',
        name: 'Student withdrawal List',
        icon: 'unordered-list',
        key: 'mainWithdrawalList',
        dontShowOnMenu: false,
        homepage: true,
        restrict: true,
        component: MainWithDrawalList,
        authority: ['admin', 'branchUser']

    },
    // {
    //     path: '/student-withdrawal',
    //     name: `Student Withdrawal`,
    //     icon: 'unordered-list',
    //     key: 'studentWithdrawal',
    //     dontShowOnMenu: true,
    //     homepage: true,
    //     component: StudentWithdrawalBackup,
    //     authority: ['student']
    // },
    {
        path: '/transaction-list',
        name: `Transaction List`,
        icon: 'unordered-list',
        key: 'transactionList',
        dontShowOnMenu: false,
        homepage: true,
        component: TransactionList,
        authority: ['student']
    },

    {
        path: '/message',
        name: 'Message',
        title: 'Message',
        icon: 'message',
        key: 'message',
        dontShowOnMenu: true,
        authority: ['admin', 'branchManager', 'branchUser'],
        children: [
            {
                path: '/message/send',
                name: 'Send Message',
                title: 'Send Message',
                component: AddPrivateMessage,
                key: 'sendMessage',
                authority: ['admin', 'branchManager', 'branchUser']
            },
            {
                path: '/message/all',
                name: 'All Message',
                title: 'All Message',
                component: AllPrivateMessage,
                key: 'allMessage',
                authority: ['admin', 'branchManager', 'branchUser']
            }
        ]
    },
    {
        path: '/scheduleMeeting',
        name: 'Schedule Meeting',
        title: 'Schedule Meeting',
        icon: 'message',
        dontShowOnMenu: true,
        key: 'scheduleMeeting',
        authority: [
            'admin',
            'branchManager',
            'branchUser',
            'agent',
            'student',
            'subAgent'
        ],
        children: [
            {
                path: '/scheduleMeeting/add',
                name: 'Add Schedule Meeting',
                title: 'Add Schedule Meeting',
                component: AddScheduleMeeting,
                key: 'addScheduleMeeting',
                authority: ['admin', 'branchManager', 'branchUser', 'agent']
            },
            {
                path: '/scheduleMeeting/all',
                name: 'All Schedule Meeting',
                title: 'All Schedule Meeting',
                component: AllScheduleMeeting,
                key: 'allScheduleMeeting',
                authority: [
                    'admin',
                    'branchManager',
                    'branchUser',
                    'agent',
                    'student',
                    'subAgent'
                ]
            }
        ]
    },
    {
        path: '/users/all-employees',
        name: 'All Users',
        title: 'All Users',
        icon: 'profile',
        key: 'allUsers',
        component: AllManagersAndUsers,
        authority: ['hr']
    },
    {
        path: '/evaluate_profile',
        name: `Evaluate Profile`,
        icon: 'search',
        key: 'searchCourse',
        homepage: true,
        restrict: true,
        component: EvaluateProfile,
        authority: [
            ...adminRightUser,
            'agent',
            'subAgent',
            'team',
            'branchManager',
            'branchUser',
            'userManager',
            'marketingManager',
            'hr',
            'student',
            'canadaOnshoreAdmin'
        ]
    },

    {
        path: '/canada-onshore-open-close-course',
        name: 'CAN- Open/Close Courses',
        icon: 'user',
        key: 'canadaOnShoreCourseListStudent',
        newLink: true,
        component: CanadaOnshoreProgramList,
        restrict: true,
        authority: [
            'student'
        ]
    },
    {
        path: '/canada-open-close-course',
        name: 'CAN- Open/Close Courses',
        icon: 'user',
        key: 'canadaOpenCloseCourse',
        newLink: true,
        component: CanadaProgramList,
        restrict: true,
        authority: [
            'admin',
            'agent',
            'subAgent',
            'branchManager',
            'branchUser',
            'userManager',
            'marketingManager',
            'hr',
            'student'
        ]
    },
    {
        path: '/uk-open-close-course',
        name: 'UK Open/Close Courses',
        icon: 'user',
        key: 'ukOpenCloseCourse',
        newLink: true,
        component: UkProgramList,
        restrict: true,
        authority: [
            'admin',
            'agent',
            'subAgent',
            'branchManager',
            'branchUser',
            'userManager',
            'marketingManager',
            'hr',
            'student'
        ]
    },
    /* {
               path: '/australia-open-close-course',
               name: 'Australia Open/Close Courses',
               icon: 'user',
               key: 'australiaOpenCloseCourse',
               authority: ['admin', 'userManager','agent'],
               component: AustraliaProgramList
           },*/
    {
        path: '/usa-open-close-course',
        name: 'USA Open/Close Courses',
        newLink: true,
        icon: 'user',
        key: 'usaOpenCloseCourse',
        component: UsaProgramList,
        restrict: true,
        authority: [
            'admin',
            'agent',
            'subAgent',
            'branchManager',
            'branchUser',
            'userManager',
            'marketingManager',
            'hr',
            'student'
        ]
    },

    {
        path: '/assessment-department',
        name: `Assessment Department`,
        icon: 'search',
        key: 'assessmentDepartment',
        restrict: true,
        component: AssessmentApplications,
        authority: ['admin', 'branchManager', 'branchUser']
    },
    {
        path: '/approved-agents',
        name: `Approved Agent`,
        icon: 'user',
        key: 'approvedAgents',
        homepage: true,
        restrict: true,
        component: ApprovedAgentsList,
        authority: [
            'admin',
            'branchManager',
            'branchUser',
            'userManager',
            'marketingManager',
            'hr'
        ]
    },
    {
        path: '/broadcast-mail',
        name: `Broadcast Mail`,
        icon: 'search',
        key: 'sendMailToAgent',
        homepage: true,
        restrict: true,
        component: SendMailToAgents,
        authority: ['admin', 'branchManager', 'branchUser', 'hr']
    },

    {
        path: '/commission',
        name: 'Commission',
        icon: 'database',
        key: 'commission',
        restrict: true,
        authority: ['admin', 'branchUser'],
        children: [
            {
                path: '/commission/university-commission',
                name: 'University Commission',
                title: 'University Commission',
                component: AllUniversityCommission,
                key: 'universityCommission',
                restrict: true,
                authority: ['admin', 'branchManager', 'branchUser']
            },
            {
                path: '/commission/received-commission',
                name: 'Received Commission',
                title: 'Received Commission',
                component: ReceivedCommissionList,
                key: 'receivedCommissionList',
                restrict: true,
                authority: ['admin', 'branchManager', 'branchUser']
            },
            {
                path: '/commission/agent-old-commission',
                name: 'Agent Old Commission',
                title: 'Agent Old Commission',
                component: OldAgentCommissionList,
                key: 'agentOldCommissionList',
                restrict: true,
                authority: ['admin', 'branchManager', 'branchUser']
            },
            {
                path: '/commission/agent-commission',
                name: 'Agent Commission',
                title: 'Agent Commission',
                component: AgentCommissionList,
                key: 'agentCommissionList',
                restrict: true,
                authority: ['admin', 'branchManager', 'branchUser']
            }
        ]
    },
    {
        path: '/onshoreCommission',
        name: 'Onshore Commission',
        icon: 'database',
        key: 'onshoreCommission',
        restrict: true,
        authority: ['onshoreAccountant', 'canadaOnshoreAdmin', 'admin', 'branchUser'],
        children: [
            {
                path: '/onshoreCommission/university-commission',
                name: 'University Commission',
                title: 'University Commission',
                component: AllUniversityOnShoreCommission,
                key: 'onShoreUniversityCommission',
                restrict: true,
                authority: ['onshoreAccountant', 'canadaOnshoreAdmin', 'admin', 'branchUser']
            },
            {
                path: '/onshoreCommission/received-commission',
                name: 'Received Commission',
                title: 'Received Commission',
                component: ReceivedOnShoreCommissionList,
                key: 'onShoreReceivedCommissionList',
                restrict: true,
                authority: ['onshoreAccountant', 'canadaOnshoreAdmin', 'admin', 'branchUser']
            },
            {
                path: '/onshoreCommission/agent-commission',
                name: 'Agent Commission',
                title: 'Agent Commission',
                component: AgentOnShoreCommissionList,
                key: 'onShoreAgentCommissionList',
                restrict: true,
                authority: ['onshoreAccountant', 'canadaOnshoreAdmin', 'admin', 'branchUser']
            }
        ]
    },

    {
        path: '/all-agent-commission',
        name: 'All Commissions',
        title: 'All Agent Commission',
        component: AllAgentCommissionList,
        key: 'allAgentCommissionList',
        icon: 'user',
        restrict: true,
        authority: ['admin', 'branchUser']
    },

    // {
    //   path: '/gic-commission-withdrawal-list',
    //   name: 'Gic Commission Withdrawal',
    //   title: 'Gic Commission Withdrawal',
    //   component: GicCommissionWithdrawal,
    //   key: 'gicCommissionWithdrawal',
    //   restrict: true,
    //   icon: 'user',
    //   authority: ['admin', 'branchUser']
    // },
    {
        path: '/referred-agent-commission',
        name: 'Referred Commission',
        title: 'Referred Commission',
        component: ReferredAgentCommission,
        key: 'referredAgentCommission',
        icon: 'user',
        restrict: true
    },

    {
        path: '/approved-withdrawals',
        title: 'Approved Withdrawals',
        name: 'Approved Withdrawals',
        icon: 'user',
        key: 'approvedWithdrawals',
        restrict: true,
        component: ApprovedWithdrawals,
        authority: ['branchUser']
    },
    {
        path: '/gic-approved-withdrawals',
        title: 'Gic Approved Withdrawals',
        name: 'Gic Approved Withdrawals',
        icon: 'user',
        key: 'gicApprovedWithdrawals',
        restrict: true,
        component: GicApprovedWithdrawals,
        authority: ['branchUser']
    },

    {
        path: '/masters',
        name: 'Masters',
        icon: 'database',
        key: 'masters',
        homepage: true,
        restrict: true,
        authority: [...adminRightUser, 'master', 'branchManager', 'userManager'],
        children: [
            {
                path: '/masters/all-Pendencies',
                name: 'All Pendencies',
                title: 'All Pendencies',
                key: 'allPendencies',
                component: AllPendency,
                authority: [...adminRightUser, 'userManager']
            },
            {
                path: '/masters/all-sample-document',
                name: 'All Sample Document',
                title: 'All Sample Document',
                key: 'allSampleDocument',
                component: AllSampleDocument,
                authority: [...adminRightUser, 'userManager']
            },
            {
                path: '/masters/add-country',
                name: 'Add Country',
                title: 'Add Country',
                dontShowOnMenu: true,
                component: AddCountry,
                authority: [...adminRightUser, 'userManager']
            },
            {
                path: '/masters/edit-country',
                name: 'Update Country',
                title: 'Update Country',
                key: 'editCountry',
                dontShowOnMenu: true,
                component: EditCountry,
                authority: [...adminRightUser, 'userManager']
            },
            {
                path: '/masters/all-country',
                name: 'All Countries',
                title: 'All Countries',
                key: 'allCountries',
                component: AllCountries,
                restrict: true,
                authority: [...adminRightUser, 'master', 'userManager']
            },

            {
                path: '/masters/add-state',
                name: 'Add State',
                title: 'Add State',
                dontShowOnMenu: true,
                component: AddState,
                authority: [...adminRightUser, 'master', 'userManager']
            },
            {
                path: '/masters/edit-state',
                name: 'Update State',
                title: 'Update State',
                key: 'editState',
                dontShowOnMenu: true,
                component: EditState,
                authority: [...adminRightUser, 'master', 'userManager']
            },
            {
                path: '/masters/all-state',
                name: 'All States',
                title: 'All States',
                component: ListAllStates,
                authority: [...adminRightUser, 'master', 'userManager']
            },
            {
                path: '/masters/add-city',
                name: 'Add city',
                title: 'Add city',
                dontShowOnMenu: true,
                component: AddCity,
                prefixComp: 'masters',
                authority: [...adminRightUser, 'master', 'userManager']
            },

            {
                path: '/masters/edit-city',
                name: 'Update city',
                title: 'Update city',
                key: 'editCity',
                dontShowOnMenu: true,
                component: EditCity,
                authority: [...adminRightUser, 'master', 'userManager']
            },
            {
                path: '/masters/all-city',
                name: 'All cities',
                title: 'All cities',
                component: ListAllCities,
                authority: [...adminRightUser, 'master', 'userManager']
            },

            {
                path: '/masters/add-university',
                name: 'Add University',
                title: 'Add University',
                dontShowOnMenu: true,
                component: AddUniversity,
                authority: [...adminRightUser, 'master', 'userManager']
            },
            {
                path: '/masters/edit-university',
                name: 'Update University',
                title: 'Update University',
                key: 'editUniversity',
                dontShowOnMenu: true,
                restrict: true,
                component: EditUniversity,
                authority: [
                    ...adminRightUser,
                    'master',
                    'branchManager',
                    'userManager',
                    'branchUser'
                ]
            },
            {
                path: '/masters/all-university',
                name: 'All Universities',
                title: 'All Universities',
                key: 'allUniversities',
                component: ListAllUniversities,
                restrict: true,
                authority: [...adminRightUser, 'master', 'branchManager', 'userManager']
            },
            {
                path: '/masters/add-course',
                name: 'Add Course',
                title: 'Add Course',
                dontShowOnMenu: true,
                component: AddCourse,
                authority: [...adminRightUser, 'master', 'userManager']
            },
            {
                path: '/masters/all-course',
                name: 'All Course',
                title: 'All Course',
                key: 'allCourse',
                component: AllCourse,
                authority: [...adminRightUser, 'master', 'userManager']
            },
            {
                path: '/masters/edit-course',
                name: 'Edit Course',
                title: 'Edit Course',
                key: 'editCourse',
                dontShowOnMenu: true,
                component: EditCourse,
                authority: [...adminRightUser, 'master', 'userManager', 'branchManager']
            },
            {
                path: '/masters/add-campus',
                name: 'Add campus',
                title: 'Add campus',
                dontShowOnMenu: true,
                component: AddCampus,
                authority: [...adminRightUser, 'master', 'userManager']
            },
            {
                path: '/masters/edit-campus',
                name: 'Update campus',
                title: 'Update campus',
                key: 'editCampus',
                dontShowOnMenu: true,
                component: EditCampus,
                authority: [...adminRightUser, 'master', 'userManager']
            },
            {
                path: '/masters/all-campus',
                name: 'All campus',
                title: 'All campus',
                key: 'allCampus',
                component: ListAllCampus,
                authority: [...adminRightUser, 'master', 'userManager']
            },
            {
                path: '/masters/assign-intake',
                name: 'All Assign Intake',
                title: 'All Assign Intake',
                key: 'assignIntake',
                component: AssignIntake,
                restrict: true,
                authority: [
                    ...adminRightUser
                    // 'master'
                ]
            },

            {
                path: '/masters/all-course-category',
                name: 'All Course Category',
                title: 'All Course Category',
                key: 'allCourseCategory',
                component: AllCourseCategory,
                authority: [...adminRightUser, 'userManager']
            },
            // {
            //   'path': '/masters/all-intake',
            //   'name': 'All Intake',
            //   'title': 'All Intake',
            //   'key': 'allIntake',
            //   'component': AllIntakes,
            //   'authority': [
            //     'admin',
            //     'master'
            //   ]
            // },
            // {
            //   'path': '/masters/add-intake',
            //   'name': 'Add intake',
            //   'title': 'Add Intake',
            //   'dontShowOnMenu': true,
            //   'component': AddIntake,
            //   'authority': [
            //     'admin',
            //     'master'
            //   ]
            // },
            // {
            //   'path': '/masters/edit-intake',
            //   'name': 'Edit intake',
            //   'key': 'edit-intake',
            //   'title': 'Edit Intake',
            //   'dontShowOnMenu': true,
            //   'component': EditIntake,
            //   'authority': [
            //     'admin',
            //     'master'
            //   ]
            // }

            {
                path: '/masters/add-banner',
                dontShowOnMenu: true,
                name: 'Add Banners',
                title: 'Add Banners',
                key: 'addBanners',
                component: AddBanners,
                authority: [...adminRightUser, 'userManager']
            },
            {
                path: '/masters/banners',
                name: 'All Banners',
                title: 'All Banners',
                key: 'banners',
                component: Banners,
                authority: [...adminRightUser, 'userManager']
            },

            {
                dontShowOnMenu: true,
                path: '/masters/add-target',
                name: 'Add Target',
                title: 'Add Target',
                component: AddTarget,
                key: 'addTarget',
                authority: [...adminRightUser, 'userManager']
            },
            {
                // 'dontShowOnMenu': true,
                // 'icon': 'user',
                path: '/masters/target-list',
                name: 'All Target List',
                title: 'All Target List',
                component: TargetList,
                key: 'targetList',
                authority: [...adminRightUser, 'userManager']
            },
            {
                dontShowOnMenu: true,
                // 'icon': 'user',
                path: '/masters/edit-target/:id',
                name: 'Edit Target',
                title: 'Edit Target',
                component: EditTarget,
                key: 'editTarget',
                authority: [...adminRightUser, 'userManager']
            },
            {
                dontShowOnMenu: true,
                // 'icon': 'user',
                path: '/masters/add-trustedStory',
                name: 'Add Trusted Story',
                title: 'Add Trusted Story',
                component: AddTrustedStory,
                key: 'addTrustedStory',
                authority: [...adminRightUser, 'userManager']
            },
            {
                // 'dontShowOnMenu': true,
                // 'icon': 'user',
                path: '/masters/trustedStory',
                name: 'All Trusted Story',
                title: 'All Trusted Story',
                component: TrustedStoryList,
                key: 'TrustedStoryList',
                authority: [...adminRightUser, 'userManager']
            },

            {
                dontShowOnMenu: true,
                // 'icon': 'user',
                path: '/masters/add-updates',
                name: 'Add Updates',
                title: 'Add Updates',
                component: AddUpdates,
                key: 'addUpdates',
                authority: [...adminRightUser, 'userManager']
            },
            {
                // 'dontShowOnMenu': true,
                // 'icon': 'user',
                path: '/masters/updates-list',
                name: 'All Updates List',
                title: 'All Updates List',
                component: UpdatesList,
                key: 'UpdatesList',
                authority: [...adminRightUser, 'userManager']
            },
            {
                path: '/masters/add-indian-city',
                name: 'Add Indian City',
                key: 'addIndianCity',
                authority: [...adminRightUser, 'userManager'],
                component: AddIndianCity
            },
            {
                path: '/masters/country-course-list',
                name: 'All Courses',
                key: 'countryWiseAllCourse',
                authority: ['branchManager'],
                component: CountryWiseCourseList
            }
        ]
    },

    {
        path: '/all-university',
        name: 'All Universities',
        title: 'All Universities',
        key: 'allUniversitiesForRyanAccounts', // created to show all universities in ryan accounts
        icon: 'profile',
        component: ListAllUniversities,
        restrict: true,
        authority: ['branchUser']
    },

    {
        path: '/reporting',
        name: `Reporting`,
        icon: 'profile',
        key: 'Reporting',
        component: Reporting,
        authority: ['admin', 'userManager', 'hr']
    },

    {
        path: '/reportingList',
        name: 'Reporting List',
        title: 'Reporting List',
        key: 'reportingList',
        dontShowOnMenu: true,
        component: ReportingList,
        authority: ['admin', 'userManager', 'hr']
    },
    {
        path: '/reportingApplicationList',
        name: 'Reporting List',
        title: 'Reporting List',
        key: 'reportingApplicationList',
        dontShowOnMenu: true,
        component: ReportingApplicationList,
        authority: ['admin', 'userManager', 'hr']
    },
    {
        path: '/marketingReportingApplicationList',
        name: 'Reporting List',
        title: 'Reporting List',
        key: 'marketingReportingApplicationList',
        dontShowOnMenu: true,
        component: MarketingReportsApplicationList,
        authority: ['admin', 'marketingManager']
    },
    {
        path: '/directUniversityMarketingReport',
        name: 'Direct University Marketing Report',
        title: 'Direct University Marketing Report',
        key: 'directUniversityMarketingReport',
        dontShowOnMenu: true,
        component: DirectReportingComponent,
        authority: ['admin', 'marketingManager', 'branchUser']
    },

    {
        path: '/institutesList',
        name: 'Institutes List',
        title: 'Institutes List',
        icon: 'user',
        key: 'institutesList',
        component: AllInstitutes,
        // restrict: true,
        authority: ['branchUser']
    },
    {
        path: '/eventList',
        name: 'Event List',
        title: 'Event List',
        icon: 'user',
        key: 'eventList',
        component: EventList,
        restrict: true,
        authority: ['admin', 'userManager', 'branchManager', 'marketingManager']
    },
    {
        path: '/event-registration',
        name: 'Event Registration',
        title: 'Event Registration',
        icon: 'user',
        key: 'eventRegistration',
        component: EventRegistration,
        authority: ['institute']
    },
    /*  {
      path: '/instituteEvents',
      name: 'Institute Events',
      title: 'Institute Events',
      icon: 'user',
      key: 'instituteEvents',
      component: InstituteEventsList,
      authority: ['institute']
    },*/
    {
        path: '/institute-events',
        name: 'Institute Events',
        title: 'Institute Events',
        icon: 'user',
        key: 'instituteEventTable',
        component: InstituteEventTable,
        authority: ['eventManager']
    },
    {
        path: '/event-calendar',
        name: 'Event Calendar',
        title: 'Event Calendar',
        icon: 'user',
        key: 'eventCalendar',
        dontShowOnMenu: true,
        component: EventCalendar,
        authority: ['counsellingManager']
    },
    {
        path: '/change-password',
        name: `Change Password`,
        icon: 'key',
        key: 'changePassword',
        dontShowOnMenu: true,
        homepage: true,
        component: ChangePassword
    },
    {
        path: '/update-password',
        name: `Update Password`,
        icon: 'key',
        key: 'updatePassword',
        dontShowOnMenu: true,
        homepage: true,
        component: UpdatePassword,
        authority: ['agent', 'subAgent']
    },
    {
        path: '/logout',
        name: `Logout`,
        icon: 'logout',
        key: 'logout',
        homepage: true,
        dontShowOnMenu: true,
        component: LogoutComponent,
        authority: ['student']
    },
    {
        path: '/download_report/:id',
        name: `downloadReport`,
        icon: 'logout',
        key: 'downloadReport',
        dontShowOnMenu: true,
        component: DownloadReport,
        restrict: true,
        authority: ['admin', 'branchManager', 'branchUser', 'marketingManager']
    },
    {
        path: '/news-enquiry',
        title: 'News Enquiry',
        name: 'News Enquiry',
        icon: 'user',
        dontShowOnMenu: true,
        key: 'newsEnquiry',
        homepage: true,
        component: AllNewsEnquiryList,
        authority: ['admin', 'studentInsurance']
    },
    {
        path: '/all-commission',
        title: 'All Commission',
        name: 'All Commission',
        icon: 'user',
        dontShowOnMenu: true,
        key: 'allCommission',
        homepage: true,
        component: AllCommission,
        authority: ['agent']
    },
    {
        path: '/birthdayList',
        title: 'Birthday List',
        name: 'Birthday List',
        icon: 'user',
        key: 'birthdayList',
        homepage: true,
        component: UserBirthdayList,
        authority: ['hr']
    },
    {
        path: '/agent-commission',
        title: 'Agent Commission',
        name: 'Agent Commission',
        icon: 'user',
        dontShowOnMenu: true,
        key: 'agentCommission',
        restrict: true,
        homepage: true,
        component: commissionAgentList,
        authority: ['admin', 'userManager', 'branchUser']
    },
    {
        path: '/commission-structure',
        title: 'Commission Structure',
        name: 'Commission',
        icon: 'user',
        dontShowOnMenu: false,
        key: 'commissionStructure',
        homepage: true,
        component: CommissionStructure,
        restrict: true,
        authority: ['agent', 'admin', 'marketingManager', 'branchUser']
    },
    {
        path: '/agent-profile',
        title: 'Profile',
        name: 'Profile',
        icon: 'user',
        dontShowOnMenu: true,
        key: 'AgentProfile',
        homepage: true,
        component: AgentProfile,
        authority: ['agent']
    },
    {
        path: '/user-profile',
        title: 'Profile',
        name: 'Profile',
        icon: 'user',
        dontShowOnMenu: true,
        key: 'userProfile',
        homepage: true,
        component: UserProfile,
        authority: ['admin', 'branchUser', 'branchManager', 'userManager']
    },
    {
        path: '/daily-leads',
        title: 'Daily Leads',
        name: 'Daily Leads',
        icon: 'user',
        dontShowOnMenu: true,
        restrict: true,
        key: 'dailyLeads',
        homepage: true,
        component: Leads
    },
    {
        path: '/agreement',
        title: 'Agreement',
        name: 'Agreement',
        icon: 'user',
        dontShowOnMenu: true,
        key: 'agreement',
        homepage: true,
        component: Agreement
    },

    {
        path: '/chat-box',
        title: 'ChatBox',
        name: 'ChatBox',
        icon: 'user',
        dontShowOnMenu: true,
        key: 'chatBox',
        homepage: true,
        component: ChatBox
    },
    {
        homepage: true,
        icon: 'user',
        path: '/assessments-warning',
        name: 'Assessments Warnings',
        title: 'Assessments Warnings',
        component: AssessmentsWarnings,
        key: 'assessmentsWarnings',
        authority: ['branchUser']
    },
    {
        homepage: true,
        icon: 'user',
        path: '/waiting-to-apply-warnings',
        name: 'Waiting To Apply Warnings',
        title: 'Waiting To Apply Warnings',
        component: WaitingToApplyWarnings,
        key: 'waitingToApplyWarnings',
        authority: ['branchUser']
    },
    {
        dontShowOnMenu: true,
        icon: 'user',
        path: '/insurance',
        name: 'Insurance',
        title: 'Insurance',
        component: InsuranceList,
        key: 'insuranceList',
        authority: ['agent', 'admin', 'branchUser', 'branchManager']
    },
    {
        dontShowOnMenu: true,
        icon: 'user',
        path: '/australiaOnShoreStudentList',
        name: 'Australia Onshore',
        title: 'Australia Onshore',
        component: AustraliaOffShoreList,
        key: 'australiaOnShoreList',
        restrict: true,
        authority: ['agent', 'admin', 'branchUser', 'branchManager']
    },
    {
        dontShowOnMenu: true,
        icon: 'user',
        path: '/canadaOnShoreStudentList',
        name: 'Canada Onshore',
        title: 'Canada Onshore',
        component: CanadaOnShoreList,
        key: 'canadaOnShoreList',
        restrict: true,
        authority: [
            'agent',
            'admin',
            'branchUser',
            'branchManager',
            'subAgent',
            'userManager'
        ]
    },

    {
        dontShowOnMenu: true,
        icon: 'user',
        path: '/australiaOnShoreStudentListTemp',
        name: 'Australia Onshore',
        title: 'Australia Onshore',
        component: IndexAustralia,
        key: 'australiaOnShoreListTemp',
        restrict: true,
        authority: ['agent', 'admin', 'branchUser', 'branchManager']
    },

    {
        dontShowOnMenu: true,
        icon: 'user',
        path: '/studentInsurance',
        name: 'Student Insurance',
        title: 'Student Insurance',
        component: StudentInsurance,
        key: 'studentInsurance',
        authority: ['agent', 'admin', 'branchUser', 'branchManager']
    },
    {
        dontShowOnMenu: true,
        icon: 'user',
        path: '/add-meetings',
        name: 'Add Meeting',
        title: 'Add Meeting',
        component: AddMeetings,
        key: 'addMeetings',
        authority: ['admin', 'branchUser']
    },
    // comment no use as of now
    {
        dontShowOnMenu: true,
        icon: 'user',
        path: '/counsellor-dashboard',
        name: 'Counsellor Dashboard',
        title: 'Counsellor Dashboard',
        component: counsellorDashboard,
        key: 'counsellorDashboard',
        authority: ['admin', 'branchUser']
    },

    {
        dontShowOnMenu: true,
        icon: 'user',
        path: '/daily-sessions',
        name: 'Daily Sessions',
        title: 'Daily Sessions',
        component: CounsellorProfile,
        key: 'dailySessions',
        authority: ['admin', 'branchUser']
    },
    {
        dontShowOnMenu: false,
        icon: 'user',
        path: '/counselling-sessions',
        name: 'Counselling Sessions',
        title: 'Counselling Sessions',
        restrict: true,
        component: CounsellingSessions,
        key: 'counsellingSessions',
        authority: ['student']
    },
    /*{
              icon: 'user',
              path: '/marketing-report',
              name: 'Marketing Report',
              title: 'Marketing Report',
              component: SelfReport,
              key: 'selfReport',
              authority: ['branchUser']
          },*/

    /* {
    path: '/marketing-agents-deal',
    name: 'Marketing Agents Deal',
    icon: 'user',
    key: 'marketingAgentsList',
    authority: ['admin', 'branchUser', 'marketingManager'],
    restrict: true,
    component: AllMarketingAgents
  },*/
    {
        path: '/university-deals',
        name: 'University Deals',
        icon: 'user',
        key: 'allRequestedList',
        authority: ['admin', 'branchUser', 'marketingManager', 'agent'],
        restrict: true,
        newLink: true,
        dontShowOnMenu: true,
        component: AllRequestedList
    },


    /*  {
      path: '/counselling-list',
      name: 'Counselling List',
      icon: 'user',
      key: 'counsellingList',
      authority: ['admin'],
      component: CounsellingList
    },*/
    {
        path: '/daily-agent-report',
        name: 'Daily Agent Report',
        icon: 'user',
        key: 'dailyAgentReport',
        restrict: true,
        authority: ['admin', 'branchManager'],
        component: DailyAgentReport
    },
    {
        path: '/direct-uni-app-report',
        name: 'Direct University App Report',
        icon: 'user',
        key: 'canadaDirectUniApp',
        dontShowOnMenu: true,
        authority: ['admin', 'branchManager'],
        component: CanadaDirectUniApp
    },

    {
        dontShowOnMenu: true,
        homepage: true,
        icon: 'user',
        path: '/add-Expense',
        name: 'Add Expense',
        title: 'Add Expense',
        component: AddExpense,
        key: 'addExpense',
        authority: ['branchUser', 'marketingManager', 'branchManager', 'eventManager']
    },

    {
        path: '/expense',
        name: 'Expense',
        icon: 'database',
        key: 'expense',
        homepage: true,
        restrict: true,
        authority: ['admin', 'branchUser'],
        children: [
            {
                path: '/expense/daily-Expense-List',
                name: 'Daily Expense List',
                key: 'dailyExpenseList',
                restrict: true,
                authority: ['admin', 'branchUser'],
                component: DailyExpenseList
            },
            {
                path: '/expense/withdrawal-List',
                name: 'Expense Withdraw List',
                key: 'expenseWithDrawList',
                restrict: true,
                authority: ['admin', 'branchUser'],
                component: ExpenseWithdrawalList
            }
        ]
    },
    {
        path: '/expense-canada',
        name: 'Canada Expense',
        icon: 'database',
        key: 'expenseCanada',
        homepage: true,
        restrict: true,
        authority: ['admin', 'canadaOnshoreAdmin'],
        children: [
            {
                path: '/expense-canada/daily-Expense-List',
                name: 'Daily Expense List',
                key: 'cadDailyExpenseList',
                restrict: true,
                authority: ['admin', 'canadaOnshoreAdmin'],
                component: DailyExpenseListForCanada
            },
            {
                path: '/cad-expense/withdrawal-List',
                name: 'Expense Withdraw List',
                key: 'cadExpenseWithDrawList',
                restrict: true,
                authority: ['admin', 'canadaOnshoreAdmin'],
                component: CadExpenseWithdrawalList
            }
        ]
    },
    {
        path: '/expense-List',
        name: 'Daily Expense List',
        icon: 'user',
        key: 'dailyExpenseListForMarketingUser',
        restrict: true,
        authority: ['branchUser', 'marketingManager', 'branchManager', 'eventManager'],
        component: DailyExpenseListForUser
    },

    {
        path: '/training-list',
        name: 'Request For Trng List',
        icon: 'user',
        key: 'trainingList',
        restrict: true,
        authority: ['admin', 'userManager'],
        component: RequestForTrainingList
    },
    {
        path: '/whats-New',
        name: 'Whats New',
        icon: 'user',
        key: 'whatsNew',
        restrict: true,
        authority: ['admin', 'userManager'],
        component: WhatsNew
    },
    {
        path: '/user-target',
        name: 'User Target',
        icon: 'user',
        key: 'userTarget',
        authority: ['admin'],
        component: UserTarget
    },
    /*
    {
      path: '/unizHomeEnquiry',
      name: 'Uniz Home Enquiry',
      icon: 'unizHomeEnquiry',
      key: 'unizHomeEnquiry',
      restrict: true,
      authority: ['admin'],
      children: [
        {
          path: '/unizHomeEnquiry/studentEnquiryList',
          name: 'Student Enquiry List',
          title: 'Student Enquiry List',
          restrict: true,
          key: 'studentEnquiryList',
          authority: ['admin'],
          component: StudentList
        },
        {
          path: '/unizHomeEnquiry/agentEnquiryList',
          name: 'Agent Enquiry List',
          title: 'Agent Enquiry List',
          restrict: true,
          key: 'agentEnquiryList',
          authority: ['admin'],
          component: AgentEnquiryList
        }
      ]
    },*/

    {
        path: '/uniz-home/enquiry-list',
        name: 'Unizhome Enquiry List',
        title: 'Unizhome Enquiry List',
        restrict: true,
        key: 'unizHomeEnquiryList',
        authority: ['unizhomeManager', 'admin'],
        component: UnizHomeEnquiryList
    },
    {
        path: '/agentFootFall',
        name: 'Agent FootFall',
        icon: 'user',
        key: 'agentFootFall',
        dontShowOnMenu: true,
        authority: ['admin'],
        component: AgentFootFall
    },
    {
        path: '/online-agents-list',
        name: 'Online Agents',
        icon: 'user',
        key: 'onlineAgentsList',
        authority: ['admin'],
        dontShowOnMenu: true,
        component: OnlineAgentsList
    },
    {
        path: '/online-team-list',
        name: 'Online Teams',
        icon: 'user',
        key: 'onlineTeamList',
        authority: ['admin'],
        dontShowOnMenu: true,
        component: OnlineTeamList
    },
    {
        path: '/canadaApplicationsList',
        name: 'Canada Applications List',
        // title: 'Canada Applications List',
        icon: 'user',
        key: 'canadaApplicationList',
        authority: ['admin', 'branchManager', 'branchUser'],
        component: CanadaLoaApplicationList,
        restrict: true
    },
    {
        path: '/supporting-team',
        name: 'Supporting Team',
        title: 'Supporting Team',
        icon: 'user',
        key: 'help',
        dontShowOnMenu: true,
        authority: ['agent'],
        component: HelpComponent
    },
    {
        path: '/agent-list',
        name: 'Not Assigned Agent list',
        icon: 'user',
        key: 'agentListForMarketingManagers',
        authority: ['marketingManager'],
        component: AgentListForMarketingManagers
    },
    {
        path: '/cambie-agents',
        name: 'Cambie Agents',
        icon: 'user',
        key: 'cambieAgentList',
        authority: ['admin', 'agent', 'cambieBranchUser', 'cambieMarketingManager', 'branchManager', 'cambieManager'],
        component: CambieAgentsList,
        restrict: true
    },
    {
        path: '/student-diversity',
        name: 'Student Diversity',
        icon: 'user',
        key: 'studentDiversity',
        authority: ['admin', 'marketingManager'],
        component: StudentDiversity
    },

    {
        path: '/assign-campus-intake',
        name: 'Assign Campus Intake',
        icon: 'user',
        key: 'assignIntakeCampusWays',
        authority: ['admin', 'userManager', 'branchManager', 'branchUser'],
        restrict: true,
        component: AssignIntakeCampusWays
    },
    {
        path: '/marketing-manager-dashboard',
        name: 'Dashboard',
        icon: 'user',
        key: 'marketingManagerDashboard',
        authority: ['admin', 'marketingManager'],
        restrict: true,
        dontShowOnMenu: true,
        component: MarketingManagerDashboard
    },
    {
        path: '/marketing-user-dashboard',
        name: 'Dashboard',
        icon: 'user',
        key: 'marketingUserDashboard',
        authority: ['admin', 'marketingManager', 'branchUser'],
        restrict: true,
        dontShowOnMenu: true,
        component: MarketingUserDashboard
    },


    {
        path: '/enroll',
        name: 'Enroll - ScholarsCrm',
        title: 'Enroll - ScholarsCrm',
        icon: 'database',
        key: 'enroll',
        homepage: true,
        restrict: true,
        authority: ['admin', 'branchUser'],
        children: [
            {
                path: '/enroll/enroll-company',
                name: 'Enroll Company',
                title: 'Enroll Company',
                key: 'addEnrolledCompany',
                component: EnrollCompany,
                restrict: true,
                authority: ['admin', 'branchUser']
            },
            {
                path: '/enroll/all-enrolled-companies',
                name: 'All Enrolled Companies',
                title: 'All Enrolled Companies',
                key: 'allEnrolledCompanies',
                component: AllEnrolledCompanies,
                restrict: true,
                authority: ['admin', 'branchUser']
            }
        ]
    },
    {
        path: '/workshops/list',
        name: 'Workshops ROI List',
        title: 'Workshops ROI List',
        key: 'workshopsRoiList',
        component: IcefMeeting,
        authority: ['admin', 'userManager', 'branchUser', 'branchManager', 'marketingManager'],
        restrict: true
    },
    {
        path: '/workshops/event-list',
        name: 'Event List',
        title: 'Event List',
        key: 'icefEventList',
        component: IcefEventsList,
        authority: ['admin', 'userManager', 'branchUser', 'branchManager', 'marketingManager'],
        dontShowOnMenu: true,
        restrict: true
    },
    {
        path: '/workshops/add-participant',
        name: 'Add Participant',
        title: 'Add Participant',
        key: 'addParticipant',
        // dontShowOnMenu: true,
        component: AddParticipants,
        authority: ['admin', 'userManager', 'branchUser', 'branchManager', 'marketingManager'],
        dontShowOnMenu: true,
        restrict: true
    },
    {
        path: '/participants/:id',
        name: 'Participants',
        title: 'Participants',
        key: 'participantsList',
        dontShowOnMenu: true,
        component: ParticipantsList,
        restrict: true,
        authority: ['admin', 'userManager', 'branchUser', 'branchManager', 'marketingManager']
    },
    {
        path: '/workshops/addEvent',
        name: 'Add Event',
        title: 'Add Event',
        key: 'addIcefEvents',
        dontShowOnMenu: true,
        component: AddIcefEvents,
        authority: ['admin', 'userManager', 'branchUser', 'branchManager', 'marketingManager'],
        restrict: true
    },

    {
        path: '/tasks-list2',
        name: 'Tasks List 2',
        dontShowOnMenu: true,
        key: 'tasksList2',
        restrict: true,
        authority: ['admin', 'userManager', 'branchManager'],
        component: TaskManager2
    },
    {
        path: '/tasks-testing',
        name: 'Task Testing',
        dontShowOnMenu: true,
        key: 'taskTesting',
        restrict: true,
        authority: ['admin', 'userManager', 'branchManager'],
        component: TaskTesting
    },

    {
        path: '/future-tasks',
        name: 'Future Tasks',
        dontShowOnMenu: true,
        key: 'futureTasksList',
        restrict: true,
        authority: ['admin', 'userManager', 'branchManager'],
        component: FutureTasks
    },
    {
        path: '/onshore-cambie-app-list',
        name: 'Onshore Cambie App List',
        icon: 'user',
        key: 'onshoreCambieAppList',
        authority: ['admin', 'cambieMarketingManager', 'cambieBranchUser', 'branchManager', 'cambieManager', 'cambieReception', 'cambieDirector'],
        component: OnshoreCambieAppList,
        restrict: true
    },
    {
        path: '/raise-a-complaint',
        name: 'Raise A Complaint',
        title: 'Raise A Complaint',
        key: 'addComplaint',
        component: AddComplaint,
        dontShowOnMenu: true,
        authority: [
            'admin',
            'agent',
            'subAgent'
        ]
    },
    {
        path: '/raise-a-complaint-list',
        name: 'Raise a Complaint List',
        title: 'Raise a Complaint List',
        key: 'complaintList',
        component: ComplaintList,
        dontShowOnMenu: true,
        restrict: true,
        authority: [
            'admin', 'branchUser', 'branchManager'
        ]
    },

    {
        path: '/onshore-fcmt-app-list',
        name: 'Onshore FCMT App List',
        icon: 'user',
        key: 'onshoreFcmtAppList',
        authority: ['admin', 'branchManager'],
        component: OnshoreFcmtAppList,
        restrict: true
    },
    {
        path: '/student/Institute-events',
        name: 'Institute events',
        title: 'Institute events',
        component: InstituteEvents,
        key: 'instituteEvents',
        authority: ['student']
    },
    {
        path: '/student/registered-events',
        name: 'Registered events',
        title: 'Registered events',
        component: RegisteredEvents,
        key: 'registeredEvents',
        authority: ['student']
    },
    {
        path: '/student/book-your-counselling',
        name: 'Book Your Counselling',
        title: 'Book Your Counselling',
        component: AddCounselling,
        key: 'bookCounselling',
        restrict: true,
        authority: ['student']
    },

    {
        path: '/canada-local-offices',
        name: 'Canada Local Offices',
        icon: 'user',
        key: 'canadaLocalOffice',
        authority: ['student'],
        component: CanadaLocalOffice
    },
    {
        path: '/contract-list',
        name: `Contract List`,
        icon: 'search',
        key: 'contractList',
        homepage: true,
        restrict: true,
        component: ContractList,
        authority: ['admin', 'marketingManager', 'branchUser']
    },

    {
        path: '/counsellor/student-list',
        name: 'Counselling Student List',
        title: 'Counselling Student List',
        restrict: true,
        key: 'counsellingStudentList',
        authority: ['branchUser', 'eventManager', 'institute'],
        component: StudentCounsellingList
    },
    {
        path: '/corner-stone/offer-application-list',
        name: 'Offer Application List',
        title: 'Offer Application List',
        restrict: true,
        key: 'cornerStoneOfferApplicationList',
        authority: ['cornerStoneManager'],
        component: CornerStoneOfferApplicationList
    },
    {
        path: '/corner-stone/loa-requested-list',
        name: 'Loa Requested List',
        title: 'Loa Requested List',
        restrict: true,
        key: 'cornerStoneLoaRequestedList',
        authority: ['cornerStoneManager'],
        component: CornerStoneLoaRequestedList
    },
    {
        path: '/expense-List-canada',
        name: 'Daily Expense List Canada',
        icon: 'user',
        key: 'dailyExpenseListForMarketingUserCanada',
        restrict: true,
        authority: ['branchUser'],
        component: DailyExpenseListForUserCanada
    },
    {
        dontShowOnMenu: true,
        homepage: true,
        icon: 'user',
        path: '/add-Expense-Canada',
        name: 'Add Expense',
        title: 'Add Expense',
        component: AddExpenseCanada,
        key: 'addExpenseCanada',
        authority: ['branchUser', 'marketingManager', 'branchManager']
    },
    {
        homepage: true,
        icon: 'user',
        path: '/add-pal',
        name: 'Upload Pal',
        title: 'Upload Pal',
        component: AddPal,
        dontShowOnMenu: true,
        key: 'uploadPal',
        authority: ['admin', 'cornerStoneManager'],
        newLink: true
    },
    {
        homepage: true,
        path: '/uploaded-pal-list',
        name: 'Uploaded PAL List',
        title: 'Uploaded PAL List',
        key: 'uploadedPalList',
        component: StudentListCornetStone,
        newLink: true,
        authority: ['admin', 'branchUser', 'cornerStoneManager']
    },

    {
        path: '/national-stone/offer-application-list',
        name: 'Offer Application List',
        title: 'Offer Application List',
        restrict: true,
        key: 'nationalCollegeOfferApplicationList',
        authority: ['nationalCollegeManager'],
        component: NationalCollegeOfferApplicationList
    },
    {
        path: '/national-stone/loa-requested-list',
        name: 'Loa Requested List',
        title: 'Loa Requested List',
        restrict: true,
        key: 'nationalCollegeLoaRequestedList',
        authority: ['nationalCollegeManager'],
        component: NationalCollegeLoaRequestedList
    },


    {
        path: '/latest-updates',
        name: 'Latest Updates',
        title: 'Latest Updates',
        key: 'latestUpdates',
        component: LatestUpdate,
        dontShowOnMenu: true,
        linkDynamic: true
    },
    {
        path: '/add-latest-updates',
        name: 'Add Latest Updates',
        title: 'Add Latest Updates',
        key: 'addLatestUpdates',
        authority: ['admin'],
        dontShowOnMenu: true,
        component: AddLatestUpdate
    },
    {
        path: '/interested-request-list',
        name: 'Interested Request List',
        title: 'Interested Request List',
        key: 'InterestedRequestList',
        authority: ['admin'],
        component: InterestedRequestList
    },
    {
        path: '/advance-commission-contract',
        title: 'Advance Commission Contract',
        name: 'Advance Commission Contract',
        key: 'advanceCommissionContract',
        homepage: true,
        dontShowOnMenu: true,
        restrict: true,
        component: AdvanceCommissionContract
    },

    {
        path: '/all-onshore-applications-list',
        name: 'All Onshore Applications List',
        title: 'All Onshore Applications List',
        icon: 'home',
        key: 'onshoreApplicationsList',
        restrict: true,
        component: OnshoreApplicationsList,
        authority: ['admin', 'cambieManager']
    },
    {
        path: '/onshore-callback-request',
        name: 'Onshore Callback Request',
        title: 'Onshore Callback Request',
        icon: 'home',
        key: 'requestCallBackList',
        // newLink: true,
        // restrict: true,
        component: RequestCallBackList,
        authority: ['branchUser'],
        newLink: true
    },
    {
        path: '/onshore-cambie-commission-list',
        name: 'Onshore Cambie Commission List',
        icon: 'user',
        key: 'OnshoreCambieCommissionList',
        authority: ['admin', 'cambieManager', 'cambieBranchUser', 'cambieDirector'],
        component: OnshoreCambieCommissionList,
        restrict: true
    },
    {
        path: '/add-complaint-department',
        name: 'Complaint Department',
        title: 'Complaint Department',
        icon: 'home',
        key: 'addComplaintDepartment',
        newLink: true,
        restrict: true,
        component: AddComplaintDepartment,
        authority: ['admin', 'userManager']
    },
    {
        path: '/institute-student-list',
        name: 'Institute Student List',
        title: 'Institute Student List',
        icon: 'user',
        key: 'instituteStudentList',
        component: InstituteStudentList,
        authority: ['institute', 'branchUser']
    },
    {
        path: '/gic-commission-report',
        name: 'Gic Commission Report',
        title: 'Gic Commission Report',
        icon: 'home',
        key: 'gicCommissionReport',
        dontShowOnMenu: true,
        component: GicCommissionReport,
        authority: ['admin']
    },
    {
        path: '/travelPlan',
        name: 'Travel Plan',
        icon: 'database',
        key: 'travelPlan',
        homepage: true,
        dontShowOnMenu: true,
        restrict: true,
        authority: ['admin', 'branchUser', 'gicMarketing'],
        children: [
            {
                path: '/travelPlan/add-travel-plan',
                name: 'Add Travel Plan',
                key: 'addTravelPlan',
                restrict: true,
                dontShowOnMenu: true,
                authority: ['admin', 'branchUser', 'gicMarketing'],
                component: AddTravelPlan
            },

            {
                path: '/travelPlan/your-travel-plans',
                name: 'Your Travel Plans',
                key: 'yourTravelPlans',
                restrict: true,
                authority: ['admin', 'branchUser', 'gicMarketing'],
                component: YourTravelPlan
            }
        ]
    },

    {
        path: '/supporting-callback-request-list',
        name: 'Supporting CallBack Req.',
        title: 'Supporting CallBack Req.',
        icon: 'home',
        key: 'callbackRequestList',
        restrict: true,
        authority: ['branchUser'],
        component: RequestCallback,
        newLink: true
    },
    {
        path: '/gic-callback-list',
        name: 'Gic CallBack Req.',
        title: 'Gic CallBack Req.',
        icon: 'home',
        key: 'gicCallbackList',
        restrict: true,
        authority: ['gicMarketing'],
        component: GicRequestCallback,
        newLink: true
    },


    {

        path: '/counsellingFollowupList',
        name: 'Counselling Followup List',
        title: 'Counselling Followup List',
        key: 'counsellingFollowupList',
        component: CounsellingFollowUpList,
        authority: ['counsellingManager', 'branchUser', 'eventManager'],
        dontShowOnMenu: false
    },
    {
        path: '/callback',
        name: 'Callback Request',
        title: 'Callback Request',
        icon: 'database',
        key: 'callbacks',
        homepage: true,
        restrict: true,
        authority: ['admin', 'agent'],
        newLink: true,
        children: [
            {
                path: '/callback/onshore-callback-request',
                name: 'Onshore Callback Req.',
                title: 'Onshore Callback Req.',
                icon: 'home',
                key: 'adminRequestCallBackList',
                component: RequestCallBackList,
                authority: ['admin', 'agent'],
                newLink: true
            },
            {
                path: '/callback/supporting-callback-request-list',
                name: 'Supporting CallBack Req.',
                title: 'Supporting CallBack Req.',
                icon: 'home',
                key: 'adminCallbackRequestList',
                restrict: true,
                authority: ['admin', 'branchUser', 'agent'],
                component: RequestCallback,
                newLink: true
            },
            {
                path: '/callback/gic-callback-list',
                name: 'Gic CallBack Req.',
                title: 'Gic CallBack Req.',
                icon: 'home',
                key: 'adminGicCallbackList',
                restrict: true,
                authority: ['admin', 'gicMarketing', 'agent'],
                component: GicRequestCallback,
                newLink: true
            }
        ]
    },
    {
        path: '/all-agent-cad-onshore-commission',
        name: 'All Commissions',
        title: 'All Agent Commission',
        component: AllAgentCanadaOnShoreCommissionListComponent,
        key: 'allAgentCanadaOnshoreCommissionList',
        icon: 'user',
        restrict: true,
        authority: ['canadaOnshoreAdmin']

    },

    {
        path: '/advance-commission-req-list',
        name: 'Advance Comm. Req. List',
        title: 'Advance Comm. Req. List',
        key: 'advanceCommissionReqList',
        authority: ['admin', 'marketingManager'],
        restrict: true,
        component: AdvanceCommissionReqList,
        newLink: true
    },
    {
        path: '/cornerStone/visa-approved-apps',
        name: 'CornerStone Visa Approved Apps',
        title: 'CornerStone Visa Approved Apps',
        icon: 'home',
        key: 'cornerStoneVisaApprovedApps',
        restrict: true,
        authority: ['admin', 'branchUser'],
        component: VisaApprovedAppsList
    },
    {
        path: '/canada-onshore-withdrawals',
        title: 'Canada Onshore Withdrawals',
        name: 'Canada Onshore Withdrawals',
        icon: 'user',
        key: 'canadaOnshoreWithdrawal',
        restrict: true,
        component: CanadaOnshoreWithdrawal,
        authority: ['canadaOnshoreAdmin']
    },

    /*  {
      path: '/add-office-visits',
      name: 'Add Office Visits',
      key: 'travelingToYourCityList',
      restrict: true,
      newLink: true,
      authority: [ 'agent'],
      component: CityWiseTravelPlan
    },*/
    {
        path: '/canada-offer-list',
        name: 'Canada Offer List',
        title: 'Canada Offer List',
        icon: 'home',
        key: 'canadaOfferList',
        restrict: true,
        newLink: true,
        component: CanadaApplicationList,
        authority: ['admin', 'gicMarketing']
    },
    {
        path: '/open-gic-draft-list',
        name: 'Open GIC Draft List',
        title: 'Open GIC Draft List',
        icon: 'home',
        key: 'openGicDraftList',
        restrict: true,
        newLink: true,
        component: DraftApplicationList,
        authority: ['admin', 'agent']
    },
    {
        path: '/searchApplication/view',
        name: 'Search Application',
        title: 'Search Application',
        key: 'searchSingleApplication',
        // dontShowOnMenu: true,
        component: SearchSingleApplication,
        authority: [
            'admin', 'itTeam'
        ]
    },
    {
        path: '/agent-current-wallet',
        name: 'Agent Current Wallet',
        icon: 'user',
        key: 'agentCurrentWallet',
        authority: ['admin', 'itTeam'],
        component: AgentCurrentWallet,
        new: true
    },
    {
        path: '/deactivated-users',
        name: 'Deactivated Users',
        icon: 'user',
        key: 'deactivatedUsers',
        authority: ['marketingManager'],
        component: DeactivatedUsers,
        new: true
    },
    {
        path: '/university-wise-reports',
        name: 'University Wise Reports',
        icon: 'user',
        key: 'universityWiseReports',
        authority: ['marketingManager'],
        component: UniversityWiseCommissions,
        new: true
    },
    {
        path: '/university-wise-assigned-agents',
        name: 'University Wise Assigned Agents',
        icon: 'user',
        restrict: true,
        key: 'universityWiseAssignedAgents',
        authority: ['branchUser', 'marketingManager', 'admin'],
        component: AgentListByDirectUniversity,
        new: true
    },

    {
        path: '/registered-agents',
        name: `Registered Agent`,
        icon: 'user',
        key: 'registeredAgent',
        homepage: true,
        component: RegisteredAgents,
        authority: [
            'itTeam'
        ]
    },
    /*{
    path: '/unverified-agents',
    name: `UnVerified Agent`,
    icon: 'user',
    key: 'unVerifiedAgents',
    homepage: true,
    component: UnVerifiedAgents,
    authority: [
      'itTeam',
      'userManager'
    ]
  }*/
    {
        path: '/live-reporting',
        name: `Live Reporting`,
        icon: 'profile',
        key: 'liveReporting',
        component: LiveReporting,
        restrict: true,
        authority: ['admin', 'branchUser', 'marketingManager']
    },
    {
        path: '/live-reporting-list',
        name: 'Live Reporting List',
        title: 'Live Reporting List',
        key: 'liveReportingList',
        dontShowOnMenu: true,
        component: LiveReportingList,
        authority: ['admin', 'branchUser', 'marketingManager']
    },
    {
        path: '/status-list-of-each-country',
        name: 'All Country Status List Counts',
        icon: 'user',
        key: 'allCountriesStatusList',
        authority: ['admin', 'marketingManager'],
        component: CountryWiseStatusCount,
        new: true,
        restrict: true,
        // dontShowOnMenu: true,
    },
    {
        path: '/marketing-all-meetings',
        name: 'All Marketing Meeting',
        title: 'All Marketing Meeting',
        component: AllMarketingMeetings,
        key: 'allMarketingMeetings',
        authority: [
            'itTeam'
        ]
    },
    {
        path: '/reactivation-list',
        name: 'Reactivation Request List',
        title: 'Reactivation Request List',
        key: 'reactivationRequestList',
        // dontShowOnMenu: true,
        newLink: true,
        component: AllReactivationRequest,
        authority: ['admin', 'userManager']
    },
    {
        path: '/callback-list',
        name: 'Callback List',
        icon: 'user',
        key: 'callbackList',
        authority: ['admin'],
        dontShowOnMenu: true,
        component: CallbackList
    },
    // ...reeudoRoutes
]

//<editor-fold desc="Functions Exports">
export const getUrlPushWrapper = (keyString, query) => {
    return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {
    if (!params) params = {}

    let keyArr = keyString.split('.')

    let val = _.find(menu, p => p.key === keyArr[0])

    if (!val) {
        return `/`
    }

    if (keyArr.length === 2) {
        val = _.find(val.children, p => p.key === keyArr[1])
    }

    if (!val) {
        return `/`
    }

    let queryString = Object.keys(params)
        .map(key => key + '=' + params[key])
        .join('&')

    return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params) => {
    let obj = getUrlObject(keyString)

    if (obj) {
        const path = new Path(obj.path)

        return push(path.build(params))
    }

    return 'error'
}

export const getUrlParams = (keyString, route) => {
    let obj = getUrlObject(keyString)

    if (obj) {
        const path = new Path(obj.path)

        return path.test(route)
    }

    return {error: true}
}

export const getUrlObject = keyString => {
    let keyArr = keyString.split('.')

    let val = _.find(menu, p => p.key === keyArr[0])

    if (!val) {
        return `/`
    }

    if (keyArr.length === 2) {
        val = _.find(val.children, p => p.key === keyArr[1])
    }

    if (!val) {
        return `/`
    }
    return val
}
//</editor-fold>

export default menu
