import {apiUrl2} from '../../../settings'

export const reeudoRatesUrl = () => {
    return apiUrl2 + '/reeudo/rates'
}
export const reeudoSourceUrl = () => {
    return apiUrl2 + '/reeudo/source'
}
export const getFinalChangesInInrUrl = () => {
    return apiUrl2 + '/reeudo/final-changes-inr'
}