import {customAxios as axios, getToken} from "../../../request";
import {getFinalChangesInInrUrl, reeudoRatesUrl, reeudoSourceUrl} from "../apis";
import {hidePageLoad, showPageLoad} from "../../../modules/actions";

export const reeudoRatesFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(reeudoRatesUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}
export const reeudoSourceFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(reeudoSourceUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}
export const getFinalChangesInInrFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(getFinalChangesInInrUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}
