import lodash from 'lodash'
import moment from 'moment'
import { apiUrl } from '../../settings'

export {
  default as CheckRights
} from '../../containers/WebComponent/CheckRights'
export { default as CustomInput } from './CustomInput'
export { default as DownloadLink } from './DownloadLinkComponent'
export { default as InputBox } from './InputBox'
export { default as FileInput } from './fileInput'
export { default as CapText } from './CapText'

export const EligibleLevel = [
  '10th',
  '10th + 3 year diploma',
  '12th',
  'Bachelor Degree',
  'Master Degree'
]

export const EnglishExamTypes = [
  'IELTS',
  'PTE',
  'TOEFL',
  'Duolingo English Test',
  'I don\'t have this'
]

export const ProgramLevel = [
  /*'Grade 9',
    'Grade 10',
    'Grade 11',
    'Grade 12',
    'English as Second Language (ESL)',*/
  '1-Year Post-Secondary Certificate',
  '2-Year Undergraduate Diploma',
  '3-Year Undergraduate Advanced Diploma',
  '3-Year Bachelor\'s Degree',
  '4-Year Bachelor\'s Degree',
  '5-Year Bachelor\'s Degree',
  'Postgraduate Certificate / Master\'s Degree',
  'Doctoral Degree (Phd, M.D., ...)'
]

export const UniversityTypes = [
  'University',
  'College' /* 'English Institute', 'High School'*/
]
export const UniversityRefArr = [
  // 'Direct',
  'Direct with Ryan',
  'Direct with Unizportal',
  'Indirect'
  // 'Channel Partner'
]
export const canadaOnshoreStatesList = [
  {
    'name': 'Alberta',
    'value': 'Alberta'
  },
  {
    'name': 'British Columbia',
    'value': 'British Columbia'
  },
  {
    'name': 'Manitoba',
    'value': 'Manitoba'
  },
  {
    'name': 'New Brunswick',
    'value': 'New Brunswick'
  },
  {
    'name': 'Newfoundland and Labrador',
    'value': 'Newfoundland and Labrador'
  },
  {
    'name': 'Northwest Territories',
    'value': 'Northwest Territories'
  },
  {
    'name': 'Nova Scotia',
    'value': 'Nova Scotia'
  },
  {
    'name': 'Nunavut',
    'value': 'Nunavut'
  },
  {
    'name': 'Ontario',
    'value': 'Ontario'
  },
  {
    'name': 'Prince Edward Island',
    'value': 'Prince Edward Island'
  },
  {
    'name': 'Quebec',
    'value': 'Quebec'
  },
  {
    'name': 'Saskatchewan',
    'value': 'Saskatchewan'
  },
  {
    'name': 'Yukon',
    'value': 'Yukon'
  }
]

export const DefaultTablePagination = (
  newParams,
  defaultPageSize = 50,
  pageSizeOptions = ['50', '100', '200', '500', '1000'],
  position = 'top'
) => {
  let params = {
    defaultPageSize,
    pageSizeOptions,
    ...newParams,
    position: position
  }
  return params
}

export const FilterOption = (input, options) => {
  return options.props.children.match(new RegExp(`^${input}`, 'ig'))
}

export const qualificationOptions = [
  'X + 3 Year Diploma',
  'XII',
  'Bachelor Degree',
  'Post Graduation',
  'XII + Diploma'
]

export const displayDate = (date, format = 'DD.MM.YYYY') => {
  if (date) {
    return moment(date).format(format)
  } else {
    return null
  }
}
export const displayTime = (date, format = 'hh:mm a') => {
  if (date) {
    return moment(date).format(format)
  } else {
    return null
  }
}

export const newFormatDisplayDate = date => {
  if (date) {
    return moment(date).format('DD.MM.YYYY')
  } else {
    return null
  }
}
export const longDisplayDate = date => {
  if (date) {
    return moment(date).format('DD.MM.YYYY,h:mm A')
  } else {
    return null
  }
}
export const longStringDate = date => {
  if (date) {
    return moment(date).format('DD/MMM/YYYY, h:mm A')
  } else {
    return null
  }
}

export const newStringDate = date => {
  if (date) {
    return moment(date).format('DD/MM/YYYY, h:mm A')
  } else {
    return null
  }
}

export const DocumentTypes = [
  'Passport',
  'IELTS',
  '10th',
  '12th',
  'Bachelor DMC',
  'Backlog',
  'Degree',
  'Experience',
  'Medium of Instruction',
  'Letter of Recommendation',
  'CV',
  'Diploma',
  'Study Permit',
  'Other'
]
export const DocumentTypesForFcmt = [
  { name: 'Passport', required: true },
  { name: 'IELTS', required: true, restrict: true },
  { name: '10th', required: true, restrict: true },
  { name: '12th', required: true, restrict: true },
  { name: 'Degree', required: false },
  { name: 'Experience', required: false },
  { name: 'Diploma', required: false },
  { name: 'Other', required: false }
]

export const DocumentTypesForCanadaOnShore = [
  { name: 'Study Permit', required: true, countryCode: 'CA_+1' },
  { name: 'Other', required: false }
]

export const DocumentTypesForRegister = [
  { name: 'Passport', required: true, countryCode: '' },
  { name: 'IELTS', required: true, countryCode: '' },
  { name: 'Study Permit', required: '', countryCode: 'CA_+1' },
  { name: '10th', required: true, countryCode: '' },
  { name: '12th', required: true, countryCode: '' },
  { name: 'Bachelor DMC', required: '', countryCode: '' },
  { name: 'Degree', required: '', countryCode: '' },
  { name: 'Other', required: '', countryCode: '' }
]
export const DocumentTypesRequiredForCanada = [
  'Passport',
  'IELTS',
  'Study Permit',
  '10th',
  '12th'
]

export const DocumentRequiredForOtherCountry = [
  'Passport',
  'IELTS',
  '10th',
  '12th'
]

export const departmentList = [
  'Offer Department',
  'Assessment Department',
  'LOA/CAS/120 Department',
  'Visa Submission Department',
  'Visa Approved Department',
  'Visa Rejected Department',
  'Interview Department',
  'Warning Department',
  'Marketing Department',
  'Account Department',
  'Counselling Department' //Changing
]

export const ukApplicationSteps = [
  { name: 'New Student' },
  { name: 'Waiting To Apply' },
  { name: 'Offer Applied' },
  { name: 'Conditional Offer Received', requiredDoc: true },
  {
    name: 'Unconditional Offer Received',
    requiredDoc: true,
    feeRequired: true
  },
  { name: 'Provide CAS Documents' },
  { name: 'CAS Applied' },
  { name: 'CAS Issued', requiredDoc: true },
  { name: 'Visa File Submitted' },
  { name: 'Visa Approved', requiredDoc: true },
  { name: 'Visa Rejected', requiredDoc: true },
  { name: 'Visa Refund Applied' },
  { name: 'Case Approved' },
  { name: 'Case Close' }
]

export const canadaApplicationSteps = [
  { name: 'New Student' },
  { name: 'Waiting To Apply' },
  { name: 'Offer Applied' },
  { name: 'Offer Received', requiredDoc: true, feeRequired: true },
  { name: 'Tuition Fee Paid & TT Received' }, // change department
  { name: 'LOA/Fee Receipt Issued', requiredDoc: true }, // change department
  { name: 'Attestation document - PAL', requiredDoc: true }, // change department
  { name: 'Visa File Submitted' },
  { name: 'Visa Approved', requiredDoc: true },
  { name: 'Visa Rejected', requiredDoc: true },
  { name: 'Visa Refund Applied' },
  { name: 'Case Approved' },
  { name: 'Case Close' }
]

export const australiaApplicationStepsBackup = [
  { name: 'New Student' },
  { name: 'Waiting To Apply' },
  { name: 'Offer Applied' },
  { name: 'Conditional Offer Received', requiredDoc: true },
  { name: 'GTE', requiredDoc: true },
  {
    name: 'Unconditional Offer Received',
    requiredDoc: true,
    feeRequired: true
  },
  { name: 'Tuition Fee Paid & TT Received' },
  { name: 'eCOE Applied', requiredDoc: true }, // change user
  { name: 'eCOE Issued', requiredDoc: true },
  { name: 'Visa File Submitted' },
  { name: 'Visa Approved', requiredDoc: true },
  { name: 'Visa Rejected', requiredDoc: true },
  { name: 'Visa Refund Applied' },
  { name: 'Case Close' }
]
export const australiaApplicationSteps = [
  { name: 'New Student' },
  { name: 'Waiting To Apply' },
  { name: 'Offer Applied' },
  { name: 'Conditional Offer Received', requiredDoc: true },
  { name: 'GTE', requiredDoc: true },
  {
    name: 'Unconditional Offer Received',
    requiredDoc: true,
    feeRequired: true
  },
  { name: 'Tuition Fee Paid & TT Received' },
  { name: 'eCOE Applied', requiredDoc: true }, // change user
  { name: 'eCOE Issued', requiredDoc: true },
  { name: 'Visa File Submitted' },
  { name: 'Visa Approved', requiredDoc: true },
  { name: 'Visa Rejected', requiredDoc: true },
  { name: 'Visa Refund Applied' },
  { name: 'Case Approved' },
  { name: 'Case Close' }
]

export const australia_L1ApplicationSteps = [
  { name: 'New Student' },
  { name: 'Waiting To Apply' },
  { name: 'Offer Applied' },
  { name: 'Conditional Offer Received' },
  { name: 'GTE' },
  { name: 'Unconditional Offer Received' },
  { name: 'Tuition Fee Paid & TT Received' },
  { name: 'eCOE Applied' }, // change user
  { name: 'eCOE Issued' },
  { name: 'Visa File Submitted' },
  { name: 'Visa Approved', requiredDoc: true },
  { name: 'Visa Rejected', requiredDoc: true },
  { name: 'Visa Refund Applied' },
  { name: 'Case Approved' },
  { name: 'Case Close' }
]

export const usaApplicationSteps = [
  { name: 'New Student' },
  { name: 'Waiting To Apply' },
  { name: 'Offer Applied' },
  { name: 'Offer Received', requiredDoc: true, feeRequired: true },
  { name: '120 Applied' },
  { name: '120 Received', requiredDoc: true },
  { name: 'Visa File Submitted' },
  { name: 'Visa Approved', requiredDoc: true },
  { name: 'Visa Rejected', requiredDoc: true },
  { name: 'Visa Refund Applied' },
  { name: 'Case Approved' },
  { name: 'Case Close' }
]

export const germanyApplicationSteps = [
  { name: 'New Student' },
  { name: 'Waiting To Apply' },
  { name: 'Offer Applied' },
  { name: 'Conditional Offer Received', requiredDoc: true },
  {
    name: 'Unconditional Offer Received',
    requiredDoc: true,
    feeRequired: true
  },
  { name: 'Tuition Fee Paid & TT Received' },
  { name: 'LOA/Fee Receipt Issued', requiredDoc: true, blockedAccount: true },
  { name: 'Visa File Submitted' },
  { name: 'Visa Approved', requiredDoc: true },
  { name: 'Visa Rejected', requiredDoc: true },
  { name: 'Visa Refund Applied' },
  { name: 'Case Approved' },
  { name: 'Case Close' }
]

export const singaporeApplicationSteps = [
  { name: 'New Student' },
  { name: 'Waiting To Apply' },
  { name: 'Offer Applied' },
  { name: 'Offer Received', requiredDoc: true },
  { name: 'Visa File Submitted' },
  { name: 'Tuition fee', requiredDoc: true },
  { name: 'Visa Approved', requiredDoc: true },
  { name: 'Visa Rejected' },
  { name: 'Visa Refund Applied' },
  { name: 'Case Approved' },
  { name: 'Case Close' }
]

export const canadaOnShoreApplicationStatusSteps = [
  { name: 'Waiting To Apply' },
  { name: 'Draft Applications' },
  { name: 'Offer Applied' },
  { name: 'Offer Received' },
  { name: 'Tuition Fee Paid & TT Received' },
  { name: 'Case Revoked' },
  { name: 'Case Approved' },
  { name: 'Case Close' }
]


export const australiaOnshoreApplicationSteps = [
  { name: 'New Student' },
  { name: 'Waiting To Apply' },
  { name: 'Offer Applied' },
  { name: 'Conditional Offer Received', requiredDoc: true },
  { name: 'GTE' },
  {
    name: 'Unconditional Offer Received',
    requiredDoc: true,
    feeRequired: true
  },
  { name: 'Tuition Fee Paid & TT Received', requiredDoc: true },
  { name: 'eCOE Applied' }, // change user
  { name: 'eCOE Issued', requiredDoc: true },
  { name: 'Visa File Submitted' },
  { name: 'Visa Approved', requiredDoc: true },
  { name: 'Visa Rejected', requiredDoc: true },
  { name: 'Visa Refund Applied' },
  { name: 'Case Approved' },
  { name: 'Case Close' }
]

export const franceApplicationSteps = [
  { name: 'Waiting To Apply' },
  // { name: 'Draft Applications' },
  { name: 'Offer Applied' },
  { name: 'Conditional' },
  { name: 'Unconditional' },
  { name: 'Tuition fee', requiredDoc: true },
  { name: 'LOA/Fee Issued', requiredDoc: true, finance: true },
  { name: 'Visa File Submitted' },
  { name: 'Visa Approved', requiredDoc: true },
  { name: 'Visa Rejected' },
  { name: 'Visa Refund Applied' },
  { name: 'Case Approved' },
  { name: 'Case Close' }
]


export const netherlandsApplicationSteps = [
  { name: 'Waiting To Apply' },
  // { name: 'Draft Applications' },
  { name: 'Offer Applied' },
  { name: 'Conditional' },
  { name: 'Unconditional' },
  { name: 'Tuition fee', requiredDoc: true },
  { name: 'LOA/Fee Issued', requiredDoc: true, blockedAccount: true },
  { name: 'Visa File Submitted' },
  { name: 'Visa Approved', requiredDoc: true },
  { name: 'Visa Rejected' },
  { name: 'Visa Refund Applied' },
  { name: 'Case Approved' },
  { name: 'Case Close' }
]
export const switzerlandApplicationSteps = [
  { name: 'Waiting To Apply' },
  // { name: 'Draft Applications' },
  { name: 'Offer Applied' },
  { name: 'Unconditional' },
  { name: 'Tuition fee', requiredDoc: true },
  { name: 'LOA/Fee Issued', requiredDoc: true },
  { name: 'Visa File Submitted' },
  { name: 'Visa Approved', requiredDoc: true },
  { name: 'Visa Rejected' },
  { name: 'Visa Refund Applied' },
  { name: 'Case Approved' },
  { name: 'Case Close' }
]


export const defaultLogo = '../../smallLogo.png'

export const nameAvatar = name => {
  if (name) {
    let nameArr = name.split(' ')
    let avatar = ''
    lodash.each(nameArr, item => {
      avatar += item.charAt(0)
    })
    return avatar
  }
}

export const WavierYear = () => {
  let years = []
  let currentYear = moment().year()
  for (let i = currentYear; i >= 2005; i--) {
    years.push(i.toString())
  }
  return years
}

export const InterviewOptions = [
  { name: 'No', value: 'no' },
  { name: 'Audio', value: 'audio' },
  { name: 'Video', value: 'video' },
  { name: 'Both', value: 'both' }
]

export const ApplicationDeskList = (country, statusList) => {
  let desk = []
  if (country.toLowerCase() == 'uk') {
    desk = lodash.clone(ukApplicationSteps)
  } else if (country.toLowerCase() == 'australia_l1') {
    desk = lodash.clone(australia_L1ApplicationSteps)
  } else if (country.toLowerCase() == 'australia') {
    desk = lodash.clone(australiaApplicationSteps)
  } else if (country.toLowerCase() == 'usa') {
    desk = lodash.clone(usaApplicationSteps)
  } else if (country.toLowerCase() == 'germany') {
    desk = lodash.clone(germanyApplicationSteps)
  } else if (country.toLowerCase() == 'singapore') {
    desk = lodash.clone(singaporeApplicationSteps)
  } else if (country.toLowerCase() == 'france') {
    desk = lodash.clone(franceApplicationSteps)
  } else if (country.toLowerCase() == 'netherlands') {
    desk = lodash.clone(netherlandsApplicationSteps)
  } else if (country.toLowerCase() == 'switzerland') {
    desk = lodash.clone(switzerlandApplicationSteps)
  } else if (country.toLowerCase() == 'australia_onshore') {
    desk = lodash.clone(australiaOnshoreApplicationSteps)
  } else {
    desk = lodash.clone(canadaApplicationSteps)
  }
  let findAppStatus = lodash.find(statusList, item => {
    if (item.status == 'Visa Approved') {
      return true
    }
  })
  if (findAppStatus) {
    desk = lodash.reject(desk, item => {
      if (
        item.name == 'Visa Rejected' ||
        item.name == 'Visa Refund Applied' ||
        item.name == 'Case Close'
      ) {
        return true
      }
    })
  } else {
    rejStatus()
  }

  function rejStatus() {
    let findRejStatus = lodash.find(statusList, item => {
      if (
        item.status == 'Visa Rejected' ||
        item.status == 'Visa Refund Applied'
      ) {
        return true
      }
    })
    if (findRejStatus) {
      desk = lodash.reject(desk, item => {
        if (item.name == 'Visa Approved' || item.name == 'Case Approved') {
          return true
        }
      })
    }
  }

  return desk
}

export const RollBackDeskList = (country, currentStatus) => {
  let rollbackDesk = []
  if (country.toLowerCase() == 'uk') {
    rollbackDesk = lodash.clone(ukApplicationSteps)
  } else if (country.toLowerCase() == 'australia_l1') {
    rollbackDesk = lodash.clone(australia_L1ApplicationSteps)
  } else if (country.toLowerCase() == 'australia') {
    rollbackDesk = lodash.clone(australiaApplicationSteps)
  } else if (country.toLowerCase() == 'usa') {
    rollbackDesk = lodash.clone(usaApplicationSteps)
  } else if (country.toLowerCase() == 'germany') {
    rollbackDesk = lodash.clone(germanyApplicationSteps)
  } else if (country.toLowerCase() == 'singapore') {
    rollbackDesk = lodash.clone(singaporeApplicationSteps)
  } else if (country.toLowerCase() == 'france') {
    rollbackDesk = lodash.clone(franceApplicationSteps)
  } else if (country.toLowerCase() == 'netherlands') {
    rollbackDesk = lodash.clone(netherlandsApplicationSteps)
  } else if (country.toLowerCase() == 'switzerland') {
    rollbackDesk = lodash.clone(switzerlandApplicationSteps)
  } else if (country.toLowerCase() == 'australia_onshore') {
    rollbackDesk = lodash.clone(australiaOnshoreApplicationSteps)
  } else {
    rollbackDesk = lodash.clone(canadaApplicationSteps)
  }
  let indexOfCurrentStatus = lodash.findIndex(rollbackDesk, item => {
    return item.name == currentStatus
  })
  rollbackDesk = rollbackDesk.splice(1, indexOfCurrentStatus - 1)
  if (currentStatus == 'Visa Approved') {
    rollbackDesk = lodash.reject(rollbackDesk, item => {
      if (item.name == 'Visa Rejected' || item.name == 'Case Close') {
        return true
      }
    })
    return rollbackDesk
  } else if (currentStatus == 'Visa Rejected') {
    rollbackDesk = lodash.reject(rollbackDesk, item => {
      if (item.name == 'Visa Approved' || item.name == 'Case Approved') {
        return true
      }
    })
    return rollbackDesk
  } else {
    return rollbackDesk
  }
}

export const statusColors = status => {
  let colorObj = [
    '#337ab7',
    '#5cb85c',
    '#5bc0de',
    '#f0ad4e',
    '#d9534f',
    '#0000FF',
    '#FF1493',
    '#F4A460',
    '#708090',
    '#808080',
    '#5F9EA0',
    '#1890ff',
    '#0872BC'
  ]
  let statusObj = {
    'New Student': '#5bc0de',
    'Waiting To Apply': '#f0ad4e',
    'Offer Applied': '#5cb85c',
    'Offer Received': '#5cb85c',
    'Conditional Offer Received': '#5cb85c',
    'Unconditional Offer Received': '#5cb85c',
    'Provide CAS Documents': '#0000FF',
    'CAS Applied': '#0000FF',
    'CAS Issued': '#0000FF',
    'eCOE Applied': '#0000FF',
    'eCOE Issued': '#0000FF',
    '120 Applied': '#0000FF',
    '120 Received': '#0000FF',
    'Tuition Fee Paid & TT Received': '#0000FF',
    'LOA/Fee Receipt Issued': '#0000FF',
    'Visa File Submitted': '#F4A460',
    'Visa Approved': '#1890ff',
    'Visa Rejected': '#d9534f',
    'Visa Refund Applied': '#F4A460',
    'Case Close': '#0872BC',
    'approved': '#5cb85c',
    'pending': '#f0ad4e',
    'rejected': '#ea7073',
    'count': '#3e40d0'
  }
  return statusObj[status] ? statusObj[status] : '#5cb85c'
}

export const filterOption = (input, options) => {
  //console.log(input, options)
  return options.props.children.match(new RegExp(`${input}`, 'ig'))
}

export const showRefundBtn = statusList => {
  let findAppStatus = lodash.find(statusList, item => {
    if (
      item.status == 'Offer Received' ||
      item.status == 'Conditional Offer Received'
    ) {
      return true
    }
  })
  if (findAppStatus) {
    return true
  } else {
    return false
  }
}

export const getStatusDate = (statusList, currentStatus) => {
  let findStatus = lodash.find(statusList, item => {
    return item.status == currentStatus
  })
  return findStatus && findStatus.date ? longDisplayDate(findStatus.date) : null
}

export const checkTuitionFeesRequired = (status, statusList) => {
  let currentStatusIndex = lodash.findIndex(statusList, item => {
    return item.name == status
  })
  if (
    statusList[currentStatusIndex] &&
    statusList[currentStatusIndex].feeRequired
  ) {
    let message = ''
    let nextStatus = statusList[currentStatusIndex + 1].name
    if (status == 'Offer Received') {
      message = 'offer letter'
    } else {
      message = 'Unconditional offer letter'
    }
    let newStatus = ''
    if (nextStatus == 'Provide CAS Documents') {
      newStatus = 'CAS'
    } else if (nextStatus == 'Tuition Fee Paid & TT Received') {
      newStatus = 'eCOE'
    } else {
      newStatus = 'LOA'
    }
    // return { show: true, nextStatus: statusList[currentStatusIndex + 1].name, infoMessage: message }
    return { show: true, nextStatus: newStatus, infoMessage: message }
  } else {
    return { show: false }
  }
}

export const ScheduleTypes = ['Audio', 'Video', 'Physical']

export const defaultLogoList = {
  defaultUniversityLogo: '../dist/img/defaultUniversity.jpg'
}

export const StatusNameObj = {
  'Conditional Offer Received': 'Conditional',
  'Unconditional Offer Received': 'Unconditional',
  'Provide CAS Documents': 'Provide CAS',
  'Tuition Fee Paid & TT Received': 'Tuition Fee',
  'LOA/Fee Receipt Issued': 'LOA/Fee Issued'
}

export const BooleanOptions = [
  { name: 'Enable', value: true },
  { name: 'Disable', value: false }
]

export const assignAgentTypeOptions = [
  { name: 'All Agents', value: 'all' },
  { name: 'Selected Agents', value: 'selected' },
  { name: 'Except Selected Agents', value: 'exceptSelected' }
]

export const assignMarketingTypeOptions = [
  { name: 'All Marketing Users', value: 'all' },
  { name: 'Selected Marketing Users', value: 'selected' },
  { name: 'Except Selected Marketing Users', value: 'exceptSelected' }
]

export const ConditionOptions = [
  { name: 'Yes', value: true },
  { name: 'No', value: false }
]

export const StudentTypesFilter = [
  { name: 'Direct', value: 'direct' },
  { name: 'Quick', value: 'quickAdd' },
  { name: 'Register', value: 'registerUser' }
]

export const Intakes = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const IntakesYears = [
  '2020',
  '2021',
  '2022',
  '2023',
  '2024',
  '2025',
  '2026',
  '2027',
  '2028',
  '2029',
  '2030'
]
export const YearList = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]

export const intakesFilter = [
  {
    label: 'May/Jun/Jul',
    value: 'May/June/July',
    season: 'Summer',
    index: 0,
    month: [5, 6, 7]
  },
  {
    label: 'Aug/Sep/Oct',
    value: 'August/September/October',
    season: 'Fall',
    index: 1,
    month: [8, 9, 10]
  },
  {
    label: 'Nov/Dev/Jan',
    value: 'November/December/January',
    season: 'Winter',
    index: 2,
    month: [11, 12, 1]
  },
  {
    label: 'Feb/Mar/April',
    value: 'February/March/April',
    season: 'Spring',
    index: 3,
    month: [2, 3, 4]
  }
]
export const MonthIndex = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12
}

export const MonthsName = [
  { name: 'January', key: 1 },
  { name: 'February', key: 2 },
  { name: 'March', key: 3 },
  { name: 'April', key: 4 },
  { name: 'May', key: 5 },
  { name: 'June', key: 6 },
  { name: 'July', key: 7 },
  { name: 'August', key: 8 },
  { name: 'September', key: 9 },
  { name: 'October', key: 10 },
  { name: 'November', key: 11 },
  { name: 'December', key: 12 }
]

export const ShowEnrollment = () => {
  let arr = ['Visa Approved', 'Case Close']
}

export const showCommissionCheck = user => {
  if (user && user.userType == 'agent') {
    return user.showCommission ? user.showCommission : false
  } else {
    return false
  }
}

export const boardList = [
  { name: 'CBSE - Central Board of Secondary Education', value: 'CBSE' },
  { name: 'ISC - Indian School Certificate', value: 'ISC' },
  { name: 'ICSE - Indian School Certificate Examinations', value: 'ICSE' },
  { name: 'NIOS – National Institute of Open Schooling', value: 'NIOS' },
  {
    name:
      'UP Board - Board of High School and Intermediate Education Uttar Pradesh',
    value: 'UP Board'
  },
  {
    name: 'JKBOSE - Jammu and Kashmir State Board of School Education',
    value: 'JKBOSE'
  },
  { name: 'RBSE - Board of Secondary Education Rajasthan', value: 'RBSE' },
  {
    name: 'HPBOSE - Himachal Pradesh Board of School Education',
    value: 'HPBOSE'
  },
  {
    name: 'MPBSE - Madhya Pradesh Board of Secondary Education',
    value: 'MPBSE'
  },
  { name: 'CGBSE - Chhattisgarh Board of Secondary Education', value: 'CGBSE' },
  { name: 'PSEB – Punjab School Education Board', value: 'PSEB' },
  { name: 'BSEH - Haryana Board of School Education (HBSE)', value: 'BSEH' },
  { name: 'BSEB - Bihar School Examination Board', value: 'BSEB' },
  {
    name: 'GSEB - Gujarat Secondary and Higher Secondary Education Board',
    value: 'GSEB'
  },
  {
    name:
      'MSBSHSE – Maharashtra State Board Of Secondary and Higher Secondary Education',
    value: 'MSBSHSE'
  },
  {
    name: 'BIEAP - Andhra Pradesh Board of Intermediate Education',
    value: 'BIEAP'
  },
  {
    name: 'BSEAP - Andhra Pradesh Board of Secondary Education',
    value: 'BSEAP'
  },
  { name: 'WBBSE - West Bengal Board of Secondary Education', value: 'WBBSE' },
  {
    name: 'WBCHSE - West Bengal Council of Higher Secondary Education',
    value: 'WBCHSE'
  }
]

export const getCurrentStatusForActivation = statusList => {
  statusList = lodash.reject(statusList, item => {
    return item.status == 'Case Close'
  })
  return statusList[statusList.length - 1]
}

export const showEnrollmentAction = statusList => {
  if (statusList && statusList.status == 'Visa Approved') {
    return true
  } else {
    return false
  }
}

export const showOnshoreCanadaEnrollmentAction = statusList => {
  if (statusList && statusList.status == 'LOA/Fee Receipt Issued') {
    return true
  } else {
    return false
  }
}

export const durationList = [
  { label: '15 minutes', value: 15 },
  { label: '30 minutes', value: 30 },
  { label: '45 minutes', value: 45 },
  { label: '1 hour', value: 60 },
  { label: '1:15 hour', value: 75 },
  { label: '1:30 hours', value: 90 },
  { label: '1:45 hours', value: 105 },
  { label: '2 hours', value: 120 }
]

export const capitalize = word => {
  const lower = word.toLowerCase()
  return word.charAt(0).toUpperCase() + lower.slice(1)
}

export const checkEmailIsValid = value => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
    return true
  } else {
    return false
  }
}

export const UniversityOptions = [
  { name: 'All University', value: 'All' },
  { name: 'Selected University', value: 'Selected' }
]
export const UniversityOptionsForOnshoreCanada = [
  { name: 'All University', value: 'All' },
  { name: 'Selected University', value: 'Selected' },
  { name: 'Except Selected University', value: 'ExceptSelected' }
]
export const CountryOptions = [
  { name: 'All Country', value: 'All' },
  { name: 'Selected Country', value: 'Selected' }
]
export const adminRightUser = ['admin', 'itTeam']
export const oxfordUniversity = '6435042a59e0d77f81a49511'
export const fcmtUniversity = '5ff6d1ac3fdb3f5fa68e9ff3'
// export const fcmtUniversity = ''
// export const cambieUniversity = '6311d05b722d9e1377bf4c29'
export const cambieUniversity = ''
export const AvalonUniversity = '5ff846d63fdb3f5fa68ea2f6'
export const GranvilleUniversity = '6173f94680ca7e59336696ab'
export const AOLUniversity = '5ff6e59d3fdb3f5fa68ea061'
export const CampBellUniversity = '61fcc4c562adc7405e7a31f4'
export const KasaHighSchool = '6230730a56bb21119b2dfaef'
export const CasaHCollege = '627b476216fab67e40701e03'
export const CambieHCollege = '6311d05b722d9e1377bf4c29'
export const LesterCollege = '5ff6e3783fdb3f5fa68ea060'
export const RoyalArtsCollege = '63cf85082b3f7a227856ec9e'
export const CanadaRoyalHighSchool = '6613c5ca7ce14759ae3b74b3'
export const CambieCollegeOnshore = '6572f5f243aa131f8cc5861c'
export const FcmtCollegeOnshore = '6572f5f243aa131f8cc58616'
export const CornerStoneCollege = '663df74476f34c0bd0f234e9'
export const NationalCollege = '663e0f5efea00011a1cb1b7e'
export const BloomsburyInstitute = '60edc6529b7afb3e8a9e6fe7'

export const directUniversityList = [
  { name: 'FCMT', id: '5ff6d1ac3fdb3f5fa68e9ff3' },

  { name: 'FCMT Onshore', id: '6572f5f243aa131f8cc58616' },
  { name: 'Cambie College', id: '6311d05b722d9e1377bf4c29' },
  /* { name: 'Avalon College', id: '5ff846d63fdb3f5fa68ea2f6' },
     { name: 'Granville College', id: '6173f94680ca7e59336696ab' },
     { name: 'Academy of Learning - Brampton', id: '5ff6e59d3fdb3f5fa68ea061' },*/
  { name: 'Campbell Institute', id: '61fcc4c562adc7405e7a31f4' },
  { name: 'Kasa High School', id: '6230730a56bb21119b2dfaef' },
  { name: 'CASA College', id: '627b476216fab67e40701e03' },
  { name: 'Lester B. Pearson', id: '5ff6e3783fdb3f5fa68ea060' },
  {
    postName: 'CIRA',
    name: 'Canada International Royal Arts College',
    id: '63cf85082b3f7a227856ec9e'
  },
  {
    name: 'Oxford College of Education',
    id: '6435042a59e0d77f81a49511'
  },
  {
    name: 'Cambie College',
    id: '6572f5f243aa131f8cc5861c'
  },
  {
    name: 'CIRA High School',
    id: '6613c5ca7ce14759ae3b74b3'
  },
  {
    name: 'Cornerstone College',
    id: '663df74476f34c0bd0f234e9'
  },
  {
    name: 'National College',
    id: NationalCollege
  }
]

export const KasaColleges = [
  { name: 'Kasa High School', id: '6230730a56bb21119b2dfaef' },
  { name: 'CASA College', id: '627b476216fab67e40701e03' }
]
export const CasaColleges = [
  { name: 'CASA College', id: '627b476216fab67e40701e03' }
  // {name: 'Kasa High School', id: '6230730a56bb21119b2dfaef'},
]
export const DirectUniversityCheck = universityId => {
  let findUniversity = lodash.find(directUniversityList, item => {
    return item.id == universityId
  })
  if (findUniversity) {
    return true
  } else {
    return false
  }
}

export const academicStatusList = [
  { name: 'Full Time', value: 'Full Time' },
  { name: 'Part Time', value: 'Part Time' }
]
export const typeOfProgramList = [
  { name: 'Vocational', value: 'Vocational' },
  { name: 'Academic', value: 'Academic' },
  { name: 'Professional', value: 'Professional' }
]

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export const departmentObj = {
  offerDepartment: 'Offer Department',
  assDepartment: 'Assessment Department',
  loaDepartment: 'LOA/CAS/120 Department',
  visaSubmission: 'Visa Submission Department',
  visaApproved: 'Visa Approved Department',
  visaRejected: 'Visa Rejected Department',
  interviewer: 'Interview Department',
  warningDepartment: 'Warning Department',
  marketing: 'Marketing Department',
  counselling: 'Counselling Department',
  accountDepartment: 'Account Department'
}

export const countryIds = {
  canada: '5dea0a52e304ca03881dd208',
  uk: '5dea0a52e304ca03881dd22e',
  australiaL2: '62e3a5b01d5bfcd430de080a',
  australia: '641c247dece3d95d343538e5',
  australiaL1: '601e424bd7e547555f7925c1',
  usa: '612c8bc76efc5360647230ba',
  germany: '62a6d7f86e44873f385759dd',
  canadaOnshore: '656f1dc7eea1b71d39b24dbe',
  australiaOnshore: '663a34fd1abc667eae61f430',
  europe: '616809855205c80d2bea7ee7',
  cyprus: '619b77b39651397b6722c02f',
  newZealand: '62b96090d0e5a26be54fc77e',
  singapore: '633bd602d4c0980738b4589b',
  france: '6425595532f5f1129f041267',
  netherlands: '660bf2a09a5dee552620e9b6',
  switzerland: '660bf2b59a5dee552620e9b7'
}
export const currencySymbols = {
  '5dea0a52e304ca03881dd208': '$',
  '5dea0a52e304ca03881dd22e': '£',
  '641c247dece3d95d343538e5': '$',
  '62e3a5b01d5bfcd430de080a': '$',
  '601e424bd7e547555f7925c1': '$',
  '62a6d7f86e44873f385759dd': '€',
  '612c8bc76efc5360647230ba': '$',
  '656f1dc7eea1b71d39b24dbe': '$',
  '663a34fd1abc667eae61f430': '$'
}


export const interviewStatusColors = status => {
  let statusObj = {
    'Not Prepared': '#ff0000',
    'Partially Prepared': '#FFFF00',
    'Fully Prepared': '#008000'
  }
  let statusColor = {
    'Not Prepared': 'white',
    'Partially Prepared': 'black',
    'Fully Prepared': 'white'
  }
  return {
    backgroundColor: statusObj[status] ? statusObj[status] : '#ff0000',
    color: statusColor[status] ? statusColor[status] : 'white'
  }
}
export let EligibilityList = [
  { name: 'minimumCasDeposit', label: 'Minimum Cas Deposit' },
  {
    name: 'maximumCasDeposit',
    label: 'Maximum Cas Deposit',
    conditional: true
  },
  { name: 'ugMinimumRequired', label: 'UG Minimum Required' },
  { name: 'ugMaximumRequired', label: 'UG Maximum Required' },
  { name: 'pgMinimumRequired', label: 'PG Minimum Required' },
  { name: 'pgMaximumRequired', label: 'PG Maximum Required' },
  { name: 'iletsMinimumForUg', label: 'Ilets Minimum For UG' },
  { name: 'iletsMaximumForUg', label: 'Ilets Maximum For UG' },
  { name: 'iletsMinimumForPg', label: 'Ilets Minimum For PG' },
  { name: 'iletsMaximumForPg', label: 'Ilets Maximum For PG' },
  { name: 'pteMinimumForUg', label: 'PTE Minimum For UG' },
  { name: 'pteMaximumForUg', label: 'PTE Maximum For UG' },
  { name: 'pteMinimumForPg', label: 'PTE Minimum For PG' },
  { name: 'pteMaximumForPg', label: 'PTE Maximum For PG' },
  { name: 'minWaiverForCbsc', label: 'Min Waiver For Cbsc' },
  { name: 'maxWaiverForCbsc', label: 'Max WaiverFor Cbsc' },
  { name: 'timeMinimumDuration', label: 'Time Minimum Duration' },
  { name: 'timeMaximumDuration', label: 'Time Maximum Duration' },
  { name: 'casTimeMinimum', label: 'CAS Time Minimum' },
  { name: 'casTimeMaximum', label: 'CAS Time Maximum' }
]

export const StudentType = {
  direct: 'Web',
  quickAdd: 'Mobile',
  registerUser: 'Registered'
}

export const managerType = [
  { name: 'University Wise', value: 'universityWise' },
  { name: 'Agent Wise', value: 'agentWise' },
  { name: 'Student Wise', value: 'studentWise' },
  { name: 'Onshore Wise', value: 'onShoreWise' }
]

export const PreferredLanguage = [
  'Arabic',
  'Bengali',
  'English',
  'French',
  'German',
  'Hindi_Urdu',
  'Indonesian',
  'Italian',
  'Japanese',
  'Korean',
  'Mandarin',
  'Malay',
  'Nepali',
  'OTHER',
  'Portuguese',
  'Russian',
  'Spanish',
  'Thai',
  'Tamil',
  'Vietnamese'
]

export const AustraliaState = ['QLD', 'VIC', 'ACT', 'SA', 'WA', 'NT', 'TAS']


export const UserTypeHumanizeName = {
  admin: 'Admin',
  branchManager: 'Branch Manager',
  marketingManager: 'Marketing Manager',
  branchUser: 'Branch User',
  master: 'Master',
  agent: 'Agent',
  subAgent: 'Sub Agent'
}

export const hidePassword = password => {
  let passwordField = ''
  for (let i = 0; i < password.length; i++) {
    passwordField = passwordField + '*'
  }
  return passwordField
}

export const InvoicePeriodCycle = [
  { name: 'Yearly', value: 12 },
  { name: 'Half Yearly', value: 6 },
  { name: 'Quarterly', value: 3 },
  { name: 'Monthly', value: 1 }
]
export const InvoiceMonthlyCycle = () => {
  let monthArr = []
  for (let i = 1; i <= 24; i++) {
    monthArr.push({
      name: `${i} ${i == 1 ? 'Month' : 'Months'}`,
      value: i
    })
  }
  return monthArr
}

export const getSemesterList = () => {
  let semesterList = []
  for (let i = 1; i <= 10; i++) {
    semesterList.push(`Semester ${i}`)
  }
  return semesterList
}
export const CommissionStatusList = [
  'Pending',
  'Commission Claimed',
  'Commission Received',
  'Commission Cancelled',
  'Agent Invoice Raised',
  'Agent Invoice Paid'
]

export const CommissionIntakeYear = () => {
  let years = []
  let currentYear = moment().year()
  for (let i = 2015; i <= currentYear; i++) {
    years.push(i.toString())
  }
  return years
}

export const CounsellorTypeList = ['Counsellor', 'Enroll']

export const getYearList = () => {
  let years = []
  for (let i = 0; i <= 10; i++) {
    years.push(
      moment()
        .add(i, 'year')
        .year()
    )
  }
  return years
}

export const universityListForMarketing = [
  {
    name: 'Cornerstone International Community College of Canada',
    sortName: 'Cornerstone College',
    id: '663df74476f34c0bd0f234e9',
    image: '../assets/directUniversities/cornerstone_college.png',
    file: `${apiUrl}/prospectus/cornerstone-brochure.pdf`
  },
  /* {
     name: 'National College',
     id: NationalCollege,
     image: '../assets/directUniversities/nationalCollege.png',
     file: `${apiUrl}/prospectus/national-college.pdf`
   },*/

  {
    name: 'Lester B. Pearson',
    sortName: 'Lester B. Pearson',
    id: '5ff6e3783fdb3f5fa68ea060',
    image: '../assets/lester.png'
  }


]


export const BloomsburyUniversityObj = {
  name: 'Bloomsbury Institute London',
  id: BloomsburyInstitute,
  image: '../featuredUniversityOld/8_Bloomsbury Institute London.jpeg'
}
export const bloomsburyShowToUser = [
  'hitanshu@unizportal.com', 'puneet@ryanconsultants.com'
]


export const ukUniversityListForMarketing = [

  {
    name: 'Bloomsbury Institute London',
    id: '60edc6529b7afb3e8a9e6fe7',
    image: '../featuredUniversityOld/8_Bloomsbury Institute London.jpeg'
  }
]
export const udaanArr = [
  { name: 'All', value: 'all' },
  { name: 'Udaan 4', value: 'udaan4' },
  { name: 'Udaan 3', value: 'udaan3' },
  { name: 'Udaan 2', value: 'udaan2' },
  { name: 'Udaan 1', value: 'udaan1' }
]

export const currentUdaan = 'udaan4'
export const prevUdaan = 'udaan3'
export const udaanQueryObj = {
  prevUdaan: 'udaan3',
  udaanNames: {
    'udaan4': 'Udaan 4',
    'udaan3': 'Udaan 3',
    'udaan2': 'Udaan 2',
    'udaan1': 'Udaan 1'
  }

}


export const streamsList = ['Commerce', 'Non Medical', 'Medical', 'Arts']

export const currencyAmt = (value, currency = 'USD') => {
  let lang = currency == 'EURO' ? 'de-DE' : 'en-US'
  let currencyName = currency == 'EURO' ? 'EUR' : 'USD'
  return value.toLocaleString(lang, {
    style: 'currency',
    currency: currencyName
  })
}
export const oxfordInterviewStatusList = [
  'Pending',
  'Pass',
  'Fail',
  'Reappear'
]

export const marketingUniversity1 = [
  { name: 'CanadaList', value: 'canada' },
  { name: 'UkList', value: 'uk' },
  { name: 'Others', value: 'other' }
]


export const marketingUniversity = {
  canadaList: [
    { label: 'Public Colleges', value: 'Public', status: 'LOA/Fee Receipt Issued' },
    // { label: 'FCMT College', value: fcmtUniversity, status: 'LOA Generated' },
    { label: 'Cambie College', value: CambieHCollege, status: 'LOA Generated' },
    { label: 'Lester B. Pearson', value: LesterCollege, status: 'LOA Generated' },
    { label: 'CIRA College', value: RoyalArtsCollege, status: 'LOA Generated' },
    { label: 'Cornerstone College', value: CornerStoneCollege, status: 'LOA Uploaded' }
    // { label: 'National College', value: NationalCollege, status: 'LOA Uploaded' }
  ],
  ukList: [
    { label: 'Public Colleges', value: 'Public', status: 'CAS Issued' },
    { label: 'Oxford College', value: oxfordUniversity, status: 'Upload Signed Visa Draft' }
  ],
  australiaList: [
    { label: 'Public Colleges', value: 'Public', status: 'eCOE Applied' }
  ],
  'canada onshoreList': [
    { label: 'Public Colleges', value: 'Public', status: 'LOA/Fee Receipt Issued' }
  ],
  others: [
    { label: 'Public Colleges', value: 'Public' }
  ]
}

export const dateFilterRange = {
  Today: [moment().startOf('day'), moment().endOf('day')],
  'Last Week': [
    moment()
      .subtract(1, 'week')
      .startOf('week'),
    moment()
      .subtract(1, 'week')
      .endOf('week')
  ],
  'Last 15 Days': [moment().subtract(14, 'days'), moment()],
  'This Month': [moment().startOf('month'), moment().endOf('month')]
}
export const expenseTypes = ['Hotel', 'Car', 'Food', 'Other Expense']

export const expenseTypes2 = ['Hotel', 'Car', 'Restaurant', 'Other Expense']

export const getDirectUniversityName = (universityId) => {
  if (universityId == 'Public') {
    return universityId
  } else {
    let findUni = lodash.find(directUniversityList, (item) => {
      return item.id == universityId
    })
    return findUni && findUni.name ? findUni.name : ''
  }
}
export const getDirectUniversityNameForSupportingTeam = (universityId) => {
  if (universityId == 'Public') {
    return universityId
  } else {
    let findUni = lodash.find(directUniversityList, (item) => {
      return item.id == universityId
    })
    if (findUni) {
      return findUni && findUni.postName ? findUni.postName : findUni.name ? findUni.name : ''
    } else {
      return null
    }
  }
}

export const getDirectUniversityNameForTravel = (universityId) => {
  if (universityId == 'Public') {
    return universityId
  } else {
    let findUni = lodash.find(directUniversityList, (item) => {
      return item.id == universityId
    })
    return findUni && findUni.name ? findUni.name : ''
  }
}

export const MonthNameByIndex = {
  '0': 'January',
  '1': 'February',
  '2': 'March',
  '3': 'April',
  '4': 'May',
  '5': 'June',
  '6': 'July',
  '7': 'August',
  '8': 'September',
  '9': 'October',
  '10': 'November',
  '11': 'December'
}

export const onshoreReferenceCodes = {
  '101': 'Sagar Kapoor (101)',
  '102': 'Life 4 tyres (102)',
  '103': 'Parwinder Singh (Roots punjabi) (103)'
}
export const onshoreReferenceName = {
  '101': 'Sagar Kapoor',
  '102': 'Life 4 tyres',
  '103': 'Parwinder Singh (Roots punjabi)'
}
export const ryanAgentId = '5fec641fe0fd4551cb5ae505'
export const cambieAgentIds = ['621a04adb320765cb12fb8bd', '650059d9ae0a72fcd27255fe', '6513fbd749887b3cc3b7b4e5', '61f9084aeb18bf101d18ad42']
export const cambieEvents = {
  ids: ['621a04adb320765cb12fb8bd'],
  showToIds: ['621a04adb320765cb12fb8bd'],
  checkAgent: (userId) => {
    userId = userId.toString()
    return cambieEvents.ids.includes(userId) ? true : false
  },
  checkApprovedAgent: (userId) => {
    userId = userId.toString()
    // return cambieEvents.showToIds.includes(userId) ? true : false
    return false
  }
}

export const onShoreOptions = [
  { name: 'Inside Canada', value: 'OnShore' },
  { name: 'Outside Canada', value: 'OffShore' }
]

export const hitanshuId = '650c3b6f1ed3d39cd52880e6'
export const sortingOptions = [
  { name: 'New to Old', value: 'new-to-old' },
  { name: 'Old to New', value: 'old-to-new' }
]
export const getDayDiff = (date) => {
  let newDate = moment(date).startOf('day')
  let totalDay = moment().diff(newDate, 'days')
  if (totalDay < 1) {
    return moment(newDate).fromNow()
  } else if (totalDay == 1) {
    return {
      days: totalDay,
      label: `${totalDay} day ago`
    }
  } else {
    return {
      days: totalDay,
      label: `${totalDay} days ago`
    }
  }
}
export const getDateInString = (date) => {
  let month = date.split('-')[0]
  let year = date.split('-').pop()
  if (month) {
    month = parseInt(month) - 1
  }
  return MonthNameByIndex[month] + ', ' + year
}
export const fcmtRevokeStatus = 'Revoked/Case Closed'

export const directUniCommission = {
  fcmt: 4500,
  cira: 5000
}
export let dealUpdateByUser = (user) => {
  if (user.userType == 'admin' || user.email == 'rohit1@unizportal.com') {
    return true
  } else {
    return false
  }
}
export let taskUpdateRights = (user) => {
  if (user.userType == 'admin' || user.userType == 'userManager') {
    return true
  } else {
    return false
  }
}
export let taskDeleteRights = (user) => {
  if (user.userType == 'admin') {
    return true
  } else {
    return false
  }
}
export let accountantEmail = 'accounts@ryanconsultants.com'
export const StatusLabel = {
  'Case Close': 'Case Closed',
  'Case Approved': 'Approved/Closed'
}
export const europeCountries = [
  'france', 'germany', 'netherlands', 'switzerland'
]

export const contractWith = [
  { name: 'Ryan', value: 0 },
  { name: 'CSS Consultants', value: 0 },
  { name: 'Unizportal India', value: 0 },
  { name: 'Unizportal FZCO', value: 0 },
  { name: 'Unizportal Canada', value: 0 }
]
export const sliderUniversityList = [
  {
    name: 'Wilfrid Laurier University - Brantford',
    countryId: '5dea0a52e304ca03881dd208',
    universityId: '62415fbf2b5934742fb51a7c',
    countryName: 'Canada',
    url: 'https://unizportal.s3.us-west-2.amazonaws.com/newimage1/8d5d9400-0918-11ef-8669-2b915ccf01f7.png'
  },
  {
    name: 'Conestoga College - Brantford',
    countryId: '5dea0a52e304ca03881dd208',
    universityId: '62135ca281adb3274c0f6223',
    countryName: 'Canada',
    url: 'https://unizportal.s3.us-west-2.amazonaws.com/newimage1/751ba220-92f9-11ec-b8b1-93db90bd0fca.jpg'
  },
  {
    name: 'University of Niagara Falls',
    countryId: '5dea0a52e304ca03881dd208',
    universityId: '65d9a2fe3cd30732a13c8896',
    countryName: 'Canada',
    url: 'https://unizportal.s3.us-west-2.amazonaws.com/newimage1/4cd2b950-d2eb-11ee-862e-dff8caea76aa.png'
  },
  {
    name: 'Algoma University - Brampton',
    countryId: '5dea0a52e304ca03881dd208',
    universityId: '6213254e3475125259d7a4fd',
    countryName: 'Canada',
    url: 'https://unizportal.s3.us-west-2.amazonaws.com/newimage1/f28c3970-92d8-11ec-b7a5-5bba1e3c45b3.jpg'
  },
  {
    name: 'Trinity Western University - Langley',
    countryId: '5dea0a52e304ca03881dd208',
    universityId: '610c164a60e8891ff27ce960',
    countryName: 'Canada',
    url: 'https://unizportal.s3.us-west-2.amazonaws.com/newimage1/ea661090-f60c-11eb-a641-3d660a1790fa.png'
  },
  {
    name: 'Canadore College - Brampton',
    countryId: countryIds.canada,
    universityId: '62173dc0490a360f3f00d390',
    countryName: 'Canada',
    url: 'https://unizportal.s3.us-west-2.amazonaws.com/newimage1/6648a1b0-9549-11ec-bf9c-ffd2d929e7c5.jpg'
  },


  {
    name: 'London South Bank University',
    countryId: '63806cde6afaf1468c8d4df3',
    universityId: '63806cde6afaf1468c8d4df3',
    countryName: 'UK',
    url: 'https://unizportal.s3.us-west-2.amazonaws.com/newimage1/b70ddc30-6c91-11ed-a1a8-fbc072ef4d3b.png'
  },
  {
    name: 'Middlesex University',
    countryId: '5dea0a52e304ca03881dd22e',
    universityId: '5fb4fd585296496bc032b08b',
    countryName: 'UK',
    url: 'https://unizportal.s3.us-west-2.amazonaws.com/newimage1/df5f9fe0-b4ad-11eb-ba75-c3690ba9047d.jpg'
  },
  {
    name: 'University of Bolton',
    countryId: '5dea0a52e304ca03881dd22e',
    universityId: '61b1cd2381ddfe6619826e4d',
    countryName: 'UK',
    url: 'https://unizportal.s3.us-west-2.amazonaws.com/newimage1/e699d360-58d2-11ec-88bb-b5fa2ee33712.jpg'
  },
  {
    name: 'University of Hertfordshire',
    countryId: '5dea0a52e304ca03881dd22e',
    universityId: '63d21ba440a0b47ae05a11a4',
    countryName: 'UK',
    url: 'https://unizportal.s3.us-west-2.amazonaws.com/newimage1/3234a6c0-280d-11ec-a9a1-1317411ae2a5.png'
  },
  {
    name: 'Nottingham Trent University',
    countryId: '5dea0a52e304ca03881dd22e',
    universityId: '617b9ea1131422252b16d3e4',
    countryName: 'UK',
    url: 'https://unizportal.s3.us-west-2.amazonaws.com/newimage1/6f854c70-3887-11ec-b072-cde860a8d5b9.PNG'
  },

  {
    name: 'University of Bedfordshire',
    countryId: '5dea0a52e304ca03881dd22e',
    universityId: '6030a53b1098b623411984a2',
    countryName: 'UK',
    url: 'https://unizportal.s3.us-west-2.amazonaws.com/newimage1/8fe03d40-d2c5-11eb-aedf-43a14248d178.png'
  },
  {
    name: 'The University of Law',
    countryId: countryIds.germany,
    universityId: '62b16121cf7282403c227810',
    countryName: 'Germany',
    url: 'https://unizportal.s3.us-west-2.amazonaws.com/newimage1/060d4990-f129-11ec-97b1-31da936e5b9c.PNG'
  },
  {
    name: 'Richmond University - The American University in London',
    countryId: countryIds.uk,
    universityId: '62b16121cf7282403c227810',
    countryName: 'UK',
    url: 'https://unizportal.s3.us-west-2.amazonaws.com/newimage1/48c7e170-c165-11ed-8561-e1e31cd99676.png'
  },
  {
    name: 'Atlantis University',
    countryId: countryIds.usa,
    universityId: '65b2401bfc951c6998cc07c5',
    countryName: 'USA',
    url: 'https://unizportal.s3.us-west-2.amazonaws.com/newimage1/4990dae0-0948-11ef-ba32-f1634a4bed9d.png'
  },
  {
    name: 'Avila University',
    countryId: countryIds.usa,
    universityId: '65b793d0fc2b6556478bed22',
    countryName: 'USA',
    url: 'https://unizportal.s3.us-west-2.amazonaws.com/newimage1/433ceac0-be9e-11ee-b1db-1140cf2acda2.png'
  },
  {
    name: 'Norquest College',
    countryId: countryIds.canada,
    universityId: '61dfd96344f158079ed69473',
    countryName: 'Canada',
    url: 'https://unizportal.s3.us-west-2.amazonaws.com/newimage1/7817c840-7446-11ec-811f-eff71f505d76.PNG'
  }
]

export let currencyArrList = [
  { name: 'INR' },
  { name: 'CAD' },
  { name: 'EUR' }
]
export let gicCommissionValue = '$175'


export const timeInterval = [
  {
    'interval': '8 AM - 1 PM'
  },
  {
    'interval': '9 AM - 2 PM'
  },
  {
    'interval': '10 AM - 3 PM'
  },
  {
    'interval': '11 AM - 4 PM'
  },
  {
    'interval': '12 PM - 5 PM'
  },
  {
    'interval': '1 PM - 6 PM'
  },
  {
    'interval': '2 PM - 7 PM'
  },
  {
    'interval': '3 PM - 8 PM'
  },
  {
    'interval': '4 PM - 9 PM'
  },
  {
    'interval': '5 PM - 10 PM'
  },
  {
    'interval': '6 PM - 11 PM'
  },
  {
    'interval': '7 PM - 12 AM'
  },
  {
    'interval': '8 PM - 1 AM'
  },
  {
    'interval': '9 PM - 2 AM'
  },
  {
    'interval': '10 PM - 3 AM'
  },
  {
    'interval': '11 PM - 4 AM'
  },
  {
    'interval': '12 AM - 5 AM'
  },
  {
    'interval': '1 AM - 6 AM'
  },
  {
    'interval': '2 AM - 7 AM'
  },
  {
    'interval': '3 AM - 8 AM'
  },
  {
    'interval': '4 AM - 9 AM'
  },
  {
    'interval': '5 AM - 10 AM'
  },
  {
    'interval': '6 AM - 11 AM'
  },
  {
    'interval': '7 AM - 12 PM'
  }
]

export const ConditionOptionsForRaiseAComplaint = [
  { name: 'Student', value: 'student' },
  { name: 'Complaint a Misbehaviour', value: 'complain_a_misbehaviour' },
  { name: 'Others', value: 'others' }
]

export const checkShowFcmtCollegeRight = (user, currentUserRights) => {
  let { userType } = user
  if (userType == 'agent' || userType == 'subAgent' || userType == 'team') {
    if (currentUserRights && currentUserRights.showFcmtCollegeRight) {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}
export const checkUploadLoaPalRight = (user) => {
  let { userType, email } = user
  if (userType == 'admin' || email == 'tarun@unizportal.com' || email == 'khushi@unizportal.com') {
    return true
  } else {
    return false
  }
}

export const checkFcmtAdminRight = (user, currentUserRights) => {
  let userData = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
  let { userType, email, countryId } = userData
  if (userType == 'admin' || (userType == 'branchManager' && countryId == countryIds.canada) || email == 'marketing@fcmtcollege.com') {
    return true
  } else {
    if (currentUserRights && currentUserRights.showFcmtCollegeRight) {
      return true
    } else {
      return false
    }
  }
}

export const agentTeamTypes = ['agent', 'subAgent', 'team']
export const cambieOnshoreUserList = [
  'cambieBranchUser',
  'cambieReception',
  'cambieDirector',
  'cambieManager',
  'cambieAccountant'
]

export const fixed2Digit = (value) => {
  if (value) {
    return parseFloat(parseFloat(value).toFixed(2))
  } else {
    return 0
  }
}

export const travelPlanStatus = [
  { name: 'Working', value: 'working' },
  { name: 'Skip', value: 'skip' }
]
